import { useLayoutEffect, useState } from 'react'
import classNames from 'classnames'

import styles from './ScrollTop.module.scss'

import { ReactComponent as ArrowSVG } from './arrow.svg'

export const ScrollTop = () => {
  const [shown, setShown] = useState(window.scrollY > 100)
  const [lifted, setLifted] = useState(false)
  const toggleScroller = () => {
    if (window.scrollY > 100) {
      setShown(true)
    } else if (shown && window.scrollY <= 100) {
      setShown(false)
    }
    if (
      window.innerHeight + Math.ceil(window.pageYOffset) >=
      document.body.offsetHeight - 2
    ) {
      setLifted(true)
    } else {
      setLifted(false)
    }
  }
  useLayoutEffect(() => {
    window.addEventListener('scroll', toggleScroller)

    return () => {
      window.removeEventListener('scroll', toggleScroller)
    }
  })

  return (
    <div
      onClick={() => {
        window.scrollTo(0, 0)
      }}
      className={classNames(styles.scroller, {
        [styles.visible]: shown,
        [styles.lifted]: lifted
      })}
    >
      <ArrowSVG />
    </div>
  )
}
