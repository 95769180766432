import { HTMLAttributes } from 'react'
import classNames from 'classnames'
import { range } from 'utils'

import styles from './StarRating.module.scss'

import { ReactComponent as StarFilledSVG } from './starFilled.svg'

type StarRatingProps = {
  count: number
  props?: HTMLAttributes<HTMLButtonElement>
}

export const StarRating = (props: StarRatingProps) => (
  <div className={classNames(styles.stars, props.props?.className)}>
    {range(5).map(index => (
      <StarFilledSVG
        key={index}
        className={classNames({
          [styles.inactive]: index >= props.count
        })}
      />
    ))}
  </div>
)
