import { YMInitializer } from 'react-yandex-metrika'
import { isDev } from 'utils'

export const YandexMetrika = () =>
  !isDev ? (
    <YMInitializer
      accounts={[56306485]}
      containerElement="ym"
      options={{
        clickmap: true,
        trackLinks: true,
        defer: true,
        accurateTrackBounce: true,
        webvisor: true
      }}
      version="2"
    />
  ) : null
