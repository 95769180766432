import { forwardRef, PropsWithChildren, useContext } from 'react'
import {
  Link as DefaultLink,
  LinkProps,
  NavLink as DefaultNavLink,
  NavLinkProps,
  BrowserRouter as DefaultBrowserRouter,
  BrowserRouterProps
} from 'react-router-dom'
import {
  HashLink as DefaultHashLink,
  HashLinkProps,
  NavHashLink as DefaultNavHashLink,
  NavHashLinkProps
} from 'react-router-hash-link'
import { LanguageContext } from 'components'

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (props: LinkProps, ref) => {
    const [currentLocale] = useContext(LanguageContext)
    const localizedPathPrefix = currentLocale === 'en' ? '/en' : ''
    return (
      <DefaultLink {...props} ref={ref} to={`${localizedPathPrefix}${props.to}`}>
        {props.children}
      </DefaultLink>
    )
  }
)

export const NavLink = forwardRef<HTMLAnchorElement, NavHashLinkProps>(
  (props: NavLinkProps, ref) => {
    const [currentLocale] = useContext(LanguageContext)
    const localizedPathPrefix = currentLocale === 'en' ? '/en' : ''
    return (
      <DefaultNavLink
        {...props}
        ref={ref}
        to={`${localizedPathPrefix}${props.to}`}
      >
        {props.children}
      </DefaultNavLink>
    )
  }
)

export const HashLink = (props: HashLinkProps) => {
  const [currentLocale] = useContext(LanguageContext)
  const localizedPathPrefix = currentLocale === 'en' ? '/en' : ''
  return (
    <DefaultHashLink {...props} to={`${localizedPathPrefix}${props.to}`}>
      {props.children}
    </DefaultHashLink>
  )
}

export const NavHashLink = (props: NavHashLinkProps) => {
  const [currentLocale] = useContext(LanguageContext)
  const localizedPathPrefix = currentLocale === 'en' ? '/en' : ''
  return (
    <DefaultNavHashLink {...props} to={`${localizedPathPrefix}${props.to}`}>
      {props.children}
    </DefaultNavHashLink>
  )
}

export const BrowserRouter = (props: PropsWithChildren<BrowserRouterProps>) => {
  const [currentLocale] = useContext(LanguageContext)
  const localizedPathPrefix = currentLocale === 'en' ? '/en' : ''
  return (
    <DefaultBrowserRouter
      {...props}
      basename={`${localizedPathPrefix}${props.basename}`}
    >
      {props.children}
    </DefaultBrowserRouter>
  )
}
