import { useState } from 'react'
import { Calendar } from './calendar/Calendar'
import { AvailableTime } from './availableTime/AvailableTime'

import styles from './TimePicker.module.scss'

type TimePickerProps = {
  onTimeConfirm: (selectedTime: Date) => void
  availableTime: {
    [date: string]: string[]
  }
}

export const TimePicker = (props: TimePickerProps) => {
  const [selectedDate, setSelectedDate] = useState<Date>()
  const availableDates = Object.keys(props.availableTime).map(str => {
    const arr = str.split('/').map(item => parseInt(item))
    return new Date(arr[2], arr[1] - 1, arr[0])
  })
  const [selectedTime, setSelectedTime] = useState<Date | null>(null)

  return (
    <div className={styles.timePickerBox}>
      <Calendar
        selectedDate={selectedDate}
        onDateSelect={setSelectedDate}
        availableDates={availableDates}
      />
      <div className={styles.rightContainer}>
        {selectedDate &&
          props.availableTime[
            new Intl.DateTimeFormat('en-GB').format(selectedDate)
          ] &&
          props.availableTime[
            new Intl.DateTimeFormat('en-GB').format(selectedDate)
          ].map((time: string) => (
            <AvailableTime
              key={time}
              time={new Date(time)}
              selectTime={setSelectedTime}
              selectedTime={selectedTime}
              onTimeConfirm={props.onTimeConfirm}
            />
          ))}
      </div>
    </div>
  )
}
