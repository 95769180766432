import { FormattedMessage } from 'react-intl'
import { useContext } from 'react'
import { LanguageContext } from 'components'
import 'moment/locale/ru'

import styles from './Confirmation.module.scss'

import { ReactComponent as ClockSVG } from './media/clock.svg'

const moment = require('moment-timezone')

export type ConfirmationProps = {
  resetSelectedTime: () => void
  selectedTime: Date
  heading?: string
}

export const Confirmation = (props: ConfirmationProps) => {
  const { selectedTime, resetSelectedTime } = props

  const [currentLocale] = useContext(LanguageContext)
  moment.locale(currentLocale)

  const weekday = moment(selectedTime).format('dddd')
  const weekdayFormatted = weekday[0].toUpperCase() + weekday.slice(1)

  const currentTimezone = moment.tz.guess()
  const date = moment.tz(selectedTime, `${currentTimezone}`).format('ll')
  const time = moment.tz(selectedTime, `${currentTimezone}`).format('LT')
  const timezone = moment.tz(selectedTime, `${currentTimezone}`).format('Z z')

  return (
    <section>
      <div className={styles.record}>
        <div className={styles.recordText}>
          {!!props.heading ? (
            props.heading
          ) : (
            <FormattedMessage
              id="timePicker.confirmation.description"
              defaultMessage="Вы собираетесь записаться на консультацию Hello Move"
            />
          )}
        </div>

        <div className={styles.datetimeContainer}>
          <ClockSVG />
          <div className={styles.datetime}>
            {date}, {weekdayFormatted}
            <br />
            {/* regex delete repeat timezone in digits when there is not abbr */}
            {time} (GMT {timezone.replace(/\s[+-].*/g, '')})
          </div>
        </div>
        <button className={styles.buttonChangeDate} onClick={resetSelectedTime}>
          <FormattedMessage
            id="timePicker.confirmation.changeDate"
            defaultMessage="Изменить время"
          />
        </button>
      </div>
    </section>
  )
}
