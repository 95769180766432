import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
  JSONString: any;
};

export type ArticleType = {
  __typename?: 'ArticleType';
  id: Scalars['ID'];
  slug: Scalars['String'];
  active: Scalars['Boolean'];
  tags: Array<TagType>;
  picture?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  htmlContent: Scalars['String'];
};

export type ClientRoadmapType = {
  __typename?: 'ClientRoadmapType';
  /** The index of a step that client is currently at */
  currentStep: Scalars['Int'];
  /** Steps data and terms */
  roadmap: Scalars['JSONString'];
  /** The name of the program that client is relocating with */
  programName: Scalars['String'];
  /** Client's name */
  name: Scalars['String'];
  /** MD5 hash of a client's id */
  clientHash: Scalars['String'];
  /** Link to the client's personal Workdrive folder */
  workdriveLink: Scalars['String'];
  /** Ids of reports, associated with client */
  reports: Array<Maybe<ReportType>>;
};

export type ConsultantType = {
  __typename?: 'ConsultantType';
  id: Scalars['ID'];
  /** Consultanst's full name */
  name: Scalars['String'];
};

/** An enumeration. */
export enum CoreReviewReviewTypeChoices {
  /** consult */
  C = 'C',
  /** webinar */
  W = 'W',
  /** relocation */
  R = 'R',
  /** other */
  O = 'O'
}

export type CountryType = {
  __typename?: 'CountryType';
  /** Country code */
  code: Scalars['String'];
  /** Country approval probability */
  probability: Scalars['Float'];
  /** Country localized names */
  names: Names;
};

export type CreateAttendeeMutation = {
  __typename?: 'CreateAttendeeMutation';
  /** Status of the request */
  status: Scalars['String'];
  /** ID of the created attendee */
  attendeeId: Scalars['ID'];
  /** Default reminder method */
  reminderMethod?: Maybe<ReminderMethod>;
};

export type CreateConsultationMutation = {
  __typename?: 'CreateConsultationMutation';
  /** Status of the request */
  status: Scalars['String'];
};

export type CreateLeadMutation = {
  __typename?: 'CreateLeadMutation';
  /** Status of the request */
  status: Scalars['String'];
};

export type CreateReferralCode = {
  __typename?: 'CreateReferralCode';
  /** Status of the request */
  status: Scalars['String'];
  /** User's referral code */
  code: Scalars['String'];
};

export type CreateReviewMutation = {
  __typename?: 'CreateReviewMutation';
  /** Status of the request */
  status: Scalars['String'];
};



export type EndscreenType = {
  __typename?: 'EndscreenType';
  id: Scalars['ID'];
  data: Scalars['JSONString'];
};


export type IntensiveSignupMutation = {
  __typename?: 'IntensiveSignupMutation';
  /** Status of the request */
  status: Scalars['String'];
};

export type InteractiveRoadmapStepMutation = {
  __typename?: 'InteractiveRoadmapStepMutation';
  /** Status of the request */
  status: Scalars['String'];
};

export type InteractiveRoadmapType = {
  __typename?: 'InteractiveRoadmapType';
  /** The ID of a step that client is currently at */
  currentStepId: Scalars['String'];
  /** Steps data and contents */
  roadmap: Scalars['JSONString'];
  /** The name of the program that client is relocating with */
  programName: Scalars['String'];
  /** Client's name */
  name: Scalars['String'];
  /** Client's plan (null if not payed yet) */
  plan?: Maybe<Scalars['String']>;
  /** Time left to finish current step */
  timeLeft: Scalars['Int'];
};


export type JobType = {
  __typename?: 'JobType';
  id: Scalars['ID'];
  title: Scalars['String'];
  advantages: Scalars['String'];
  shortDescription: Scalars['String'];
  description: Scalars['String'];
};

export type LanguageMutation = {
  __typename?: 'LanguageMutation';
  /** List of all the languages with applied changes */
  allLangs: Array<Maybe<LanguageType>>;
};

export type LanguageType = {
  __typename?: 'LanguageType';
  id: Scalars['ID'];
  name: Scalars['String'];
  textsJson: Scalars['String'];
  editedAt: Scalars['DateTime'];
};

export type MediaType = {
  __typename?: 'MediaType';
  id: Scalars['ID'];
  name: Scalars['String'];
  link: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  visible: Scalars['Boolean'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Mutation for picking a QuizProgram */
  programPick: ProgramPickMutation;
  /** Mutation for signing up in the Quiz */
  quizSignup: QuizSignUp;
  /** Mutation for updating current step in Solo Roadmap */
  updateStep: SoloRoadmapStepMutation;
  /** Mutation for unsubscribing */
  unsubscribe: UnsubscribeMutation;
  /** Mutation for updating current step in Solo Roadmap */
  updateIrStep: InteractiveRoadmapStepMutation;
  /** Mutation for requesting help with current step in Solo Roadmap */
  requestHelp: SoloRoadmapHelpRequest;
  /** Mutation that creates new consultation for client */
  scheduleConsultation: CreateConsultationMutation;
  /** Mutation that reschedules existing consultation */
  rescheduleConsultation: RescheduleConsultationMutation;
  /** Mutation for updating Calendar event with attendee's info */
  uploadQuestionnaire: UploadQuestionnaireMutation;
  /** Mutation for creating new payment */
  createPurchase: PurchaseMutation;
  /** Mutation for creating new Webinar Attendee */
  webinarSignup: CreateAttendeeMutation;
  /** Mutation for siging up for the Intensive */
  intensiveSignup: IntensiveSignupMutation;
  /** Mutation for updating Webinar Attendee's reminder type */
  setReminderType: SetReminderMutation;
  /** Mutation for creating new review */
  createReview: CreateReviewMutation;
  /** Mutation for creating ZohoCRM Lead with source "Asked for call" */
  createLead: CreateLeadMutation;
  /** Mutation for signing up to our newsletter */
  newsSubscribe: NewsletterMutation;
  /** Mutation for applieng to the vacancy */
  jobApplication: SendResumeMutation;
  /** Mutation for creating referral code */
  createReferralCode: CreateReferralCode;
  /** Mutation for receiving the prize */
  receivePrize: ReceivePrizeMutation;
  /** Mutation for changing texts of a particular language */
  updateLang: LanguageMutation;
};


export type MutationProgramPickArgs = {
  programId: Scalars['String'];
};


export type MutationQuizSignupArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  phone: Scalars['String'];
};


export type MutationUpdateStepArgs = {
  newStepId: Scalars['String'];
};


export type MutationUpdateIrStepArgs = {
  newStepId: Scalars['String'];
};


export type MutationRequestHelpArgs = {
  note: Scalars['String'];
};


export type MutationScheduleConsultationArgs = {
  consultantId: Scalars['String'];
  selectedTime: Scalars['String'];
};


export type MutationRescheduleConsultationArgs = {
  rescheduleHash: Scalars['String'];
  selectedTime: Scalars['String'];
};


export type MutationUploadQuestionnaireArgs = {
  applicants: Scalars['String'];
  consultationHash: Scalars['String'];
  description: Scalars['String'];
  questions: Scalars['String'];
  relocationGoal: Scalars['String'];
  termsFrom: Scalars['String'];
  termsTo: Scalars['String'];
};


export type MutationCreatePurchaseArgs = {
  additionalInfo?: Maybe<Scalars['JSONString']>;
  email: Scalars['String'];
  method?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  purchaseType?: Maybe<Scalars['String']>;
};


export type MutationWebinarSignupArgs = {
  clickmeetingId: Scalars['Int'];
  email: Scalars['String'];
  name: Scalars['String'];
  note: Scalars['String'];
  phone: Scalars['String'];
  utmInfo?: Maybe<Scalars['JSONString']>;
};


export type MutationIntensiveSignupArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
};


export type MutationSetReminderTypeArgs = {
  attendeeId: Scalars['String'];
  reminderType: Scalars['String'];
};


export type MutationCreateReviewArgs = {
  name: Scalars['String'];
  rating: Scalars['Int'];
  reviewText: Scalars['String'];
  reviewType: Scalars['String'];
};


export type MutationCreateLeadArgs = {
  additionalInfo?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  utmInfo?: Maybe<Scalars['JSONString']>;
};


export type MutationNewsSubscribeArgs = {
  email: Scalars['String'];
};


export type MutationJobApplicationArgs = {
  email: Scalars['String'];
  job: Scalars['String'];
  link: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
};


export type MutationCreateReferralCodeArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
};


export type MutationReceivePrizeArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  utmInfo?: Maybe<Scalars['JSONString']>;
};


export type MutationUpdateLangArgs = {
  langChanges: Scalars['String'];
  name: Scalars['String'];
};

export type Names = {
  __typename?: 'Names';
  /** Name in the English locale */
  en: Scalars['String'];
  /** Name in the Russian locale */
  ru: Scalars['String'];
};

export type NewsletterMutation = {
  __typename?: 'NewsletterMutation';
  /** Status of the request */
  status: Scalars['String'];
};

/** Obtain JSON Web Token mutation */
export type ObtainJsonWebToken = {
  __typename?: 'ObtainJSONWebToken';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
};

export type Program = {
  __typename?: 'Program';
  id: Scalars['ID'];
  name: Scalars['String'];
  data: Scalars['JSONString'];
  /** ID of the program's direction */
  directionId: Scalars['String'];
};

export type ProgramPickMutation = {
  __typename?: 'ProgramPickMutation';
  /** Status of the request */
  status: Scalars['String'];
};

export type ProgramType = {
  __typename?: 'ProgramType';
  id: Scalars['ID'];
  clientRoadmap: Scalars['String'];
  soloRoadmap: Scalars['String'];
  displayOnWebsite: Scalars['Boolean'];
  lang: Scalars['String'];
  slug: Scalars['String'];
  isRecommended: Scalars['Boolean'];
  programType: Scalars['String'];
  country: CountryType;
  countryName?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  resultDocument: Scalars['String'];
  terms: Scalars['String'];
  difficulty: Scalars['Int'];
  shortDescription: Scalars['String'];
  headings: Scalars['JSONString'];
  description: Scalars['String'];
  advantagesJson: Scalars['String'];
  conditionsJson: Scalars['String'];
  stepsJson: Scalars['String'];
  faqJson: Scalars['String'];
  otherPrograms: Array<ProgramType>;
  legacyRoadmap: Scalars['String'];
  /** Programs's fullsize picture */
  picture: Scalars['String'];
  /** Programs's thumbnail picture */
  thumbnail: Scalars['String'];
  /** Programs's card picture */
  cardPicture: Scalars['String'];
  programSet: Array<ProgramType>;
  /** Other programs with the same program type */
  sameTypePrograms: Array<Maybe<ProgramType>>;
};

export type PurchaseMutation = {
  __typename?: 'PurchaseMutation';
  /** Status of the request. "Failed" means selected time is no longer available */
  status: Scalars['String'];
  /** Stripe seesion id */
  sessionId?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  /** Retreive all available tags */
  tags: Array<Maybe<TagType>>;
  /** Retrieve Relopedia articles */
  allArticles: Array<Maybe<ArticleType>>;
  /** Total amount of articles */
  articlesCount: Scalars['Int'];
  /** Retrieve specific Relopedia article by id or slug */
  article: ArticleType;
  /** List of all jobs */
  allJobs?: Maybe<Array<Maybe<JobType>>>;
  /** List of all media articles */
  allMedia: Array<Maybe<MediaType>>;
  /** Returns code of the prize for the current ip address */
  gamePrize: Scalars['String'];
  /** Returns code of the newly generated prize for the current ip address */
  openGamePrize: Scalars['String'];
  /** Time left till personal special offer is over */
  personalBonusTimeLeft: Scalars['Int'];
  /** Time left till global special offer is over */
  globalBonusTimeLeft: Scalars['Int'];
  /** Available time for consultations. To check specific consultant, its id should be passed */
  availableTime: Scalars['JSONString'];
  /** Client's Care Manager (if available) */
  careManager?: Maybe<ConsultantType>;
  /** Client's Relocation Roadmap */
  clientRoadmap: ClientRoadmapType;
  /** Client's Solo Roadmap */
  soloRoadmap: SoloRoadmapType;
  /** Client's Interactive Roadmap */
  interactiveRoadmap: InteractiveRoadmapType;
  /** Weekly report on client's progress */
  report: ReportType;
  /** Returns one of "/client_area", "/relocation" or "/platform" */
  roadmapType: Scalars['String'];
  /** This query contains all Quiz-related models */
  quiz: Quiz;
  /** List of all reviews */
  allReviews: Array<Maybe<ReviewType>>;
  /** List of all programs */
  allPrograms: Array<Maybe<ProgramType>>;
  program: ProgramType;
  /** Data for the event's endscreen page */
  endscreen: EndscreenType;
  /** List of all the languages */
  allLangs: Array<Maybe<LanguageType>>;
  /** Single language that contains all the texts */
  lang: LanguageType;
  /** Mutation for obtaining new JSON Web Token */
  login: ObtainJsonWebToken;
  /** Mutation for verifying provided JWT */
  verifyToken: Verify;
};


export type QueryAllArticlesArgs = {
  count: Scalars['Int'];
  offset: Scalars['Int'];
};


export type QueryArticleArgs = {
  slug: Scalars['String'];
};


export type QueryAvailableTimeArgs = {
  consultantIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryReportArgs = {
  reportId: Scalars['Int'];
};


export type QueryRoadmapTypeArgs = {
  username: Scalars['String'];
};


export type QueryAllProgramsArgs = {
  lang: Scalars['String'];
};


export type QueryProgramArgs = {
  slug: Scalars['String'];
  lang: Scalars['String'];
};


export type QueryEndscreenArgs = {
  clickmeetingId: Scalars['String'];
};


export type QueryLangArgs = {
  name: Scalars['String'];
};


export type QueryLoginArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
};


export type QueryVerifyTokenArgs = {
  token?: Maybe<Scalars['String']>;
};

export type Quiz = {
  __typename?: 'Quiz';
  /** List of matched directions */
  directions: Array<Maybe<QuizDirectionType>>;
  /** List of available residence types for selected directions */
  residenceTypes: Array<Maybe<ResidenceType>>;
  /** JSON containing contents of the Quiz frames */
  framesData: Scalars['JSONString'];
  /** List of programs that match user preferences */
  programs: Array<Maybe<Program>>;
  /** Program's full info */
  program: Program;
};


export type QuizResidenceTypesArgs = {
  selectedDirections: Array<Maybe<Scalars['String']>>;
};


export type QuizProgramsArgs = {
  locale: Scalars['String'];
  selectedCountryCodes: Array<Maybe<Scalars['String']>>;
  residenceTypeIds: Array<Maybe<Scalars['String']>>;
};


export type QuizProgramArgs = {
  programId: Scalars['String'];
};

export type QuizDirectionType = {
  __typename?: 'QuizDirectionType';
  id: Scalars['ID'];
  /** Direction localized names */
  names: Names;
  /** Direction localized descriptions */
  descriptions: Names;
  /** Probability that this direction matches user preferences */
  probability: Scalars['Float'];
};


export type QuizDirectionTypeProbabilityArgs = {
  criteriaIds: Array<Maybe<Scalars['String']>>;
};

export type QuizSignUp = {
  __typename?: 'QuizSignUp';
  /** Status of the request */
  status: Scalars['String'];
};

export type ReceivePrizeMutation = {
  __typename?: 'ReceivePrizeMutation';
  /** Status of the request */
  status: Scalars['String'];
};

export enum ReminderMethod {
  Em = 'EM',
  Wa = 'WA',
  Tg = 'TG'
}

export type ReportType = {
  __typename?: 'ReportType';
  id: Scalars['ID'];
  date?: Maybe<Scalars['Date']>;
  /** Report JSON data */
  data: Scalars['JSONString'];
};

export type RescheduleConsultationMutation = {
  __typename?: 'RescheduleConsultationMutation';
  /** Status of the request */
  status: Scalars['String'];
};

export type ResidenceType = {
  __typename?: 'ResidenceType';
  id: Scalars['ID'];
  /** Residence type localized names */
  names: Names;
  /** Residence type localized descriptions */
  descriptions: Names;
  /** Residence type coutries */
  countryList: Array<Maybe<CountryType>>;
};

export type ReviewType = {
  __typename?: 'ReviewType';
  id: Scalars['ID'];
  source: Scalars['String'];
  writerName: Scalars['String'];
  reviewType: CoreReviewReviewTypeChoices;
  rating: Scalars['Int'];
  reviewText: Scalars['String'];
  date: Scalars['Date'];
};

export type SendResumeMutation = {
  __typename?: 'SendResumeMutation';
  /** Status of the request */
  status: Scalars['String'];
};

export type SetReminderMutation = {
  __typename?: 'SetReminderMutation';
  /** Status of the request */
  status: Scalars['String'];
};

export type SoloRoadmapHelpRequest = {
  __typename?: 'SoloRoadmapHelpRequest';
  /** Status of the request */
  status: Scalars['String'];
};

export type SoloRoadmapStepMutation = {
  __typename?: 'SoloRoadmapStepMutation';
  /** Status of the request */
  status: Scalars['String'];
};

export type SoloRoadmapType = {
  __typename?: 'SoloRoadmapType';
  /** The ID of a step that client is currently at */
  currentStepId: Scalars['String'];
  /** Steps data and terms */
  roadmap: Scalars['JSONString'];
  /** The name of the program that client is relocating with */
  programName: Scalars['String'];
  /** Client's name */
  name: Scalars['String'];
};

export type TagType = {
  __typename?: 'TagType';
  id: Scalars['ID'];
  code: Scalars['String'];
  articleSet: Array<ArticleType>;
};

export type UnsubscribeMutation = {
  __typename?: 'UnsubscribeMutation';
  /** Status of the request */
  status: Scalars['String'];
};

export type UploadQuestionnaireMutation = {
  __typename?: 'UploadQuestionnaireMutation';
  /** Status of the request */
  status: Scalars['String'];
};

export type Verify = {
  __typename?: 'Verify';
  payload: Scalars['GenericScalar'];
};

export type ContactUsMutationVariables = Exact<{
  name: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  utmInfo: Scalars['JSONString'];
  additionalData?: Maybe<Scalars['String']>;
}>;


export type ContactUsMutation = (
  { __typename?: 'Mutation' }
  & { createLead: (
    { __typename?: 'CreateLeadMutation' }
    & Pick<CreateLeadMutation, 'status'>
  ) }
);

export type CreateNewReviewMutationVariables = Exact<{
  rating: Scalars['Int'];
  name: Scalars['String'];
  reviewText: Scalars['String'];
  reviewType: Scalars['String'];
}>;


export type CreateNewReviewMutation = (
  { __typename?: 'Mutation' }
  & { createReview: (
    { __typename?: 'CreateReviewMutation' }
    & Pick<CreateReviewMutation, 'status'>
  ) }
);

export type GetAllArticlesQueryVariables = Exact<{
  count: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetAllArticlesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'articlesCount'>
  & { allArticles: Array<Maybe<(
    { __typename?: 'ArticleType' }
    & Pick<ArticleType, 'slug' | 'picture' | 'title'>
    & { tags: Array<(
      { __typename?: 'TagType' }
      & Pick<TagType, 'code'>
    )> }
  )>>, tags: Array<Maybe<(
    { __typename?: 'TagType' }
    & Pick<TagType, 'code'>
  )>> }
);

export type GetAllMediaQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllMediaQuery = (
  { __typename?: 'Query' }
  & { allMedia: Array<Maybe<(
    { __typename?: 'MediaType' }
    & Pick<MediaType, 'id' | 'name' | 'icon' | 'link'>
  )>> }
);

export type GetAllProgramsQueryVariables = Exact<{
  lang: Scalars['String'];
}>;


export type GetAllProgramsQuery = (
  { __typename?: 'Query' }
  & { allPrograms: Array<Maybe<(
    { __typename?: 'ProgramType' }
    & Pick<ProgramType, 'isRecommended' | 'slug' | 'programType' | 'terms' | 'difficulty' | 'shortDescription' | 'cardPicture'>
    & { country: (
      { __typename?: 'CountryType' }
      & Pick<CountryType, 'code'>
      & { names: (
        { __typename?: 'Names' }
        & Pick<Names, 'en' | 'ru'>
      ) }
    ) }
  )>> }
);

export type GetAllTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllTagsQuery = (
  { __typename?: 'Query' }
  & { tags: Array<Maybe<(
    { __typename?: 'TagType' }
    & Pick<TagType, 'id' | 'code'>
  )>> }
);

export type GetArticleQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetArticleQuery = (
  { __typename?: 'Query' }
  & { article: (
    { __typename?: 'ArticleType' }
    & Pick<ArticleType, 'htmlContent' | 'picture' | 'title'>
  ) }
);

export type GetEndscreenQueryVariables = Exact<{
  clickmeetingId: Scalars['String'];
}>;


export type GetEndscreenQuery = (
  { __typename?: 'Query' }
  & { endscreen: (
    { __typename?: 'EndscreenType' }
    & Pick<EndscreenType, 'id' | 'data'>
  ) }
);

export type GetLangQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type GetLangQuery = (
  { __typename?: 'Query' }
  & { lang: (
    { __typename?: 'LanguageType' }
    & Pick<LanguageType, 'name' | 'textsJson'>
  ) }
);

export type GetProgramQueryVariables = Exact<{
  slug: Scalars['String'];
  lang: Scalars['String'];
}>;


export type GetProgramQuery = (
  { __typename?: 'Query' }
  & { program: (
    { __typename?: 'ProgramType' }
    & Pick<ProgramType, 'thumbnail' | 'picture' | 'programType' | 'shortDescription' | 'headings' | 'description' | 'advantagesJson' | 'resultDocument' | 'conditionsJson' | 'stepsJson' | 'faqJson'>
    & { country: (
      { __typename?: 'CountryType' }
      & Pick<CountryType, 'code'>
      & { names: (
        { __typename?: 'Names' }
        & Pick<Names, 'en' | 'ru'>
      ) }
    ), sameTypePrograms: Array<Maybe<(
      { __typename?: 'ProgramType' }
      & Pick<ProgramType, 'slug'>
      & { country: (
        { __typename?: 'CountryType' }
        & Pick<CountryType, 'code'>
        & { names: (
          { __typename?: 'Names' }
          & Pick<Names, 'en' | 'ru'>
        ) }
      ) }
    )>>, otherPrograms: Array<(
      { __typename?: 'ProgramType' }
      & Pick<ProgramType, 'slug' | 'programType' | 'terms' | 'difficulty' | 'shortDescription' | 'cardPicture'>
      & { country: (
        { __typename?: 'CountryType' }
        & { names: (
          { __typename?: 'Names' }
          & Pick<Names, 'ru' | 'en'>
        ) }
      ) }
    )> }
  ) }
);

export type GetProgramNamesQueryVariables = Exact<{
  lang: Scalars['String'];
}>;


export type GetProgramNamesQuery = (
  { __typename?: 'Query' }
  & { allPrograms: Array<Maybe<(
    { __typename?: 'ProgramType' }
    & Pick<ProgramType, 'slug' | 'programType'>
    & { country: (
      { __typename?: 'CountryType' }
      & { names: (
        { __typename?: 'Names' }
        & Pick<Names, 'en' | 'ru'>
      ) }
    ) }
  )>> }
);

export type GetReviewsDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReviewsDataQuery = (
  { __typename?: 'Query' }
  & { allReviews: Array<Maybe<(
    { __typename?: 'ReviewType' }
    & Pick<ReviewType, 'source' | 'writerName' | 'reviewType' | 'rating' | 'reviewText' | 'date'>
  )>> }
);

export type GetTimeLeftQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTimeLeftQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'personalBonusTimeLeft' | 'globalBonusTimeLeft'>
);

export type IntensiveReferralSignupMutationVariables = Exact<{
  name: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
}>;


export type IntensiveReferralSignupMutation = (
  { __typename?: 'Mutation' }
  & { intensiveSignup: (
    { __typename?: 'IntensiveSignupMutation' }
    & Pick<IntensiveSignupMutation, 'status'>
  ) }
);

export type WebinarSignupMutationVariables = Exact<{
  name: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
  note: Scalars['String'];
  utmInfo: Scalars['JSONString'];
}>;


export type WebinarSignupMutation = (
  { __typename?: 'Mutation' }
  & { webinarSignup: (
    { __typename?: 'CreateAttendeeMutation' }
    & Pick<CreateAttendeeMutation, 'status' | 'attendeeId'>
  ) }
);

export type GetClientPlanQueryVariables = Exact<{ [key: string]: never; }>;


export type GetClientPlanQuery = (
  { __typename?: 'Query' }
  & { interactiveRoadmap: (
    { __typename?: 'InteractiveRoadmapType' }
    & Pick<InteractiveRoadmapType, 'plan'>
  ) }
);

export type UpdateInteractiveRoadmapStepMutationVariables = Exact<{
  newStepId: Scalars['String'];
}>;


export type UpdateInteractiveRoadmapStepMutation = (
  { __typename?: 'Mutation' }
  & { updateIrStep: (
    { __typename?: 'InteractiveRoadmapStepMutation' }
    & Pick<InteractiveRoadmapStepMutation, 'status'>
  ) }
);

export type GetCurrentRoadmapInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentRoadmapInfoQuery = (
  { __typename?: 'Query' }
  & { interactiveRoadmap: (
    { __typename?: 'InteractiveRoadmapType' }
    & Pick<InteractiveRoadmapType, 'currentStepId' | 'roadmap' | 'programName' | 'plan'>
  ) }
);

export type GetQuizStepTimeLeftQueryVariables = Exact<{ [key: string]: never; }>;


export type GetQuizStepTimeLeftQuery = (
  { __typename?: 'Query' }
  & { interactiveRoadmap: (
    { __typename?: 'InteractiveRoadmapType' }
    & Pick<InteractiveRoadmapType, 'timeLeft'>
  ) }
);

export type CancelSubscriptionMutationVariables = Exact<{ [key: string]: never; }>;


export type CancelSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { unsubscribe: (
    { __typename?: 'UnsubscribeMutation' }
    & Pick<UnsubscribeMutation, 'status'>
  ) }
);

export type GetQuizUserNameQueryVariables = Exact<{ [key: string]: never; }>;


export type GetQuizUserNameQuery = (
  { __typename?: 'Query' }
  & { interactiveRoadmap: (
    { __typename?: 'InteractiveRoadmapType' }
    & Pick<InteractiveRoadmapType, 'name'>
  ) }
);

export type GetReferrerCodeMutationVariables = Exact<{
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
}>;


export type GetReferrerCodeMutation = (
  { __typename?: 'Mutation' }
  & { createReferralCode: (
    { __typename?: 'CreateReferralCode' }
    & Pick<CreateReferralCode, 'code' | 'status'>
  ) }
);

export type JobApplicationMutationVariables = Exact<{
  name: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
  link: Scalars['String'];
  job: Scalars['String'];
}>;


export type JobApplicationMutation = (
  { __typename?: 'Mutation' }
  & { jobApplication: (
    { __typename?: 'SendResumeMutation' }
    & Pick<SendResumeMutation, 'status'>
  ) }
);

export type NewsletterSubscribeMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type NewsletterSubscribeMutation = (
  { __typename?: 'Mutation' }
  & { newsSubscribe: (
    { __typename?: 'NewsletterMutation' }
    & Pick<NewsletterMutation, 'status'>
  ) }
);

export type ReceiveGamePrizeMutationVariables = Exact<{
  name: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
  utmInfo: Scalars['JSONString'];
}>;


export type ReceiveGamePrizeMutation = (
  { __typename?: 'Mutation' }
  & { receivePrize: (
    { __typename?: 'ReceivePrizeMutation' }
    & Pick<ReceivePrizeMutation, 'status'>
  ) }
);

export type GetHelpMutationVariables = Exact<{
  note: Scalars['String'];
}>;


export type GetHelpMutation = (
  { __typename?: 'Mutation' }
  & { requestHelp: (
    { __typename?: 'SoloRoadmapHelpRequest' }
    & Pick<SoloRoadmapHelpRequest, 'status'>
  ) }
);

export type RequestPaymentMutationVariables = Exact<{
  name: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  purchaseType: Scalars['String'];
  method?: Maybe<Scalars['String']>;
  additionalInfo?: Maybe<Scalars['JSONString']>;
}>;


export type RequestPaymentMutation = (
  { __typename?: 'Mutation' }
  & { createPurchase: (
    { __typename?: 'PurchaseMutation' }
    & Pick<PurchaseMutation, 'status' | 'sessionId'>
  ) }
);

export type RequestRescheduleMutationVariables = Exact<{
  rescheduleHash: Scalars['String'];
  newTime: Scalars['String'];
}>;


export type RequestRescheduleMutation = (
  { __typename?: 'Mutation' }
  & { rescheduleConsultation: (
    { __typename?: 'RescheduleConsultationMutation' }
    & Pick<RescheduleConsultationMutation, 'status'>
  ) }
);

export type SaveLangMutationVariables = Exact<{
  name: Scalars['String'];
  langChanges: Scalars['String'];
}>;


export type SaveLangMutation = (
  { __typename?: 'Mutation' }
  & { updateLang: (
    { __typename?: 'LanguageMutation' }
    & { allLangs: Array<Maybe<(
      { __typename?: 'LanguageType' }
      & Pick<LanguageType, 'name' | 'textsJson' | 'editedAt'>
    )>> }
  ) }
);

export type ScheduleClientConsultationMutationVariables = Exact<{
  consultantId: Scalars['String'];
  selectedTime: Scalars['String'];
}>;


export type ScheduleClientConsultationMutation = (
  { __typename?: 'Mutation' }
  & { scheduleConsultation: (
    { __typename?: 'CreateConsultationMutation' }
    & Pick<CreateConsultationMutation, 'status'>
  ) }
);

export type QuestionnaireUploadMutationVariables = Exact<{
  consultationHash: Scalars['String'];
  applicants: Scalars['String'];
  description: Scalars['String'];
  termsFrom: Scalars['String'];
  termsTo: Scalars['String'];
  relocationGoal: Scalars['String'];
  questions: Scalars['String'];
}>;


export type QuestionnaireUploadMutation = (
  { __typename?: 'Mutation' }
  & { uploadQuestionnaire: (
    { __typename?: 'UploadQuestionnaireMutation' }
    & Pick<UploadQuestionnaireMutation, 'status'>
  ) }
);

export type SetReminderTypeMutationVariables = Exact<{
  attendeeId: Scalars['String'];
  reminderType: Scalars['String'];
}>;


export type SetReminderTypeMutation = (
  { __typename?: 'Mutation' }
  & { setReminderType: (
    { __typename?: 'SetReminderMutation' }
    & Pick<SetReminderMutation, 'status'>
  ) }
);

export type UpdateSoloRoadmapStepMutationVariables = Exact<{
  newStepId: Scalars['String'];
}>;


export type UpdateSoloRoadmapStepMutation = (
  { __typename?: 'Mutation' }
  & { updateStep: (
    { __typename?: 'SoloRoadmapStepMutation' }
    & Pick<SoloRoadmapStepMutation, 'status'>
  ) }
);

export type GetAllJobsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllJobsQuery = (
  { __typename?: 'Query' }
  & { allJobs?: Maybe<Array<Maybe<(
    { __typename?: 'JobType' }
    & Pick<JobType, 'title' | 'advantages' | 'shortDescription' | 'description'>
  )>>> }
);

export type GetAllTextsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllTextsQuery = (
  { __typename?: 'Query' }
  & { allLangs: Array<Maybe<(
    { __typename?: 'LanguageType' }
    & Pick<LanguageType, 'name' | 'textsJson' | 'editedAt'>
  )>> }
);

export type GetAvailableTimeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAvailableTimeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'availableTime'>
);

export type GetClientCareManagerQueryVariables = Exact<{ [key: string]: never; }>;


export type GetClientCareManagerQuery = (
  { __typename?: 'Query' }
  & { careManager?: Maybe<(
    { __typename?: 'ConsultantType' }
    & Pick<ConsultantType, 'id' | 'name'>
  )> }
);

export type GetClientRoadmapQueryVariables = Exact<{ [key: string]: never; }>;


export type GetClientRoadmapQuery = (
  { __typename?: 'Query' }
  & { clientRoadmap: (
    { __typename?: 'ClientRoadmapType' }
    & Pick<ClientRoadmapType, 'roadmap' | 'currentStep' | 'programName' | 'name' | 'workdriveLink' | 'clientHash'>
    & { reports: Array<Maybe<(
      { __typename?: 'ReportType' }
      & Pick<ReportType, 'id' | 'date'>
    )>> }
  ) }
);

export type GetClientWeeklyReportQueryVariables = Exact<{
  reportId: Scalars['Int'];
}>;


export type GetClientWeeklyReportQuery = (
  { __typename?: 'Query' }
  & { report: (
    { __typename?: 'ReportType' }
    & Pick<ReportType, 'date' | 'data'>
  ) }
);

export type GetConsultantTimesheetQueryVariables = Exact<{
  consultantId: Scalars['String'];
}>;


export type GetConsultantTimesheetQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'availableTime'>
);

export type GetGamePrizeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGamePrizeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'gamePrize'>
);

export type GetSoloRoadmapQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSoloRoadmapQuery = (
  { __typename?: 'Query' }
  & { soloRoadmap: (
    { __typename?: 'SoloRoadmapType' }
    & Pick<SoloRoadmapType, 'name' | 'programName' | 'roadmap' | 'currentStepId'>
  ) }
);

export type LoginQueryVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'roadmapType'>
  & { login: (
    { __typename?: 'ObtainJSONWebToken' }
    & Pick<ObtainJsonWebToken, 'token'>
  ) }
);

export type WinGamePrizeQueryVariables = Exact<{ [key: string]: never; }>;


export type WinGamePrizeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'openGamePrize'>
);

export type GetDirectionsQueryVariables = Exact<{
  criteriaIds: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;


export type GetDirectionsQuery = (
  { __typename?: 'Query' }
  & { quiz: (
    { __typename?: 'Quiz' }
    & { directions: Array<Maybe<(
      { __typename?: 'QuizDirectionType' }
      & Pick<QuizDirectionType, 'id' | 'probability'>
      & { names: (
        { __typename?: 'Names' }
        & Pick<Names, 'en' | 'ru'>
      ), descriptions: (
        { __typename?: 'Names' }
        & Pick<Names, 'en' | 'ru'>
      ) }
    )>> }
  ) }
);

export type GetQuizProgramQueryVariables = Exact<{
  programId: Scalars['String'];
}>;


export type GetQuizProgramQuery = (
  { __typename?: 'Query' }
  & { quiz: (
    { __typename?: 'Quiz' }
    & { program: (
      { __typename?: 'Program' }
      & Pick<Program, 'name' | 'data'>
    ) }
  ) }
);

export type GetQuizProgramDataQueryVariables = Exact<{
  programId: Scalars['String'];
}>;


export type GetQuizProgramDataQuery = (
  { __typename?: 'Query' }
  & { quiz: (
    { __typename?: 'Quiz' }
    & { program: (
      { __typename?: 'Program' }
      & Pick<Program, 'data' | 'name'>
    ) }
  ) }
);

export type GetQuizProgramsQueryVariables = Exact<{
  locale: Scalars['String'];
  residenceTypeIds: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
  selectedCountryCodes: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;


export type GetQuizProgramsQuery = (
  { __typename?: 'Query' }
  & { quiz: (
    { __typename?: 'Quiz' }
    & { programs: Array<Maybe<(
      { __typename?: 'Program' }
      & Pick<Program, 'id' | 'name' | 'directionId'>
    )>> }
  ) }
);

export type GetResidenceTypesQueryVariables = Exact<{
  selectedDirections: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;


export type GetResidenceTypesQuery = (
  { __typename?: 'Query' }
  & { quiz: (
    { __typename?: 'Quiz' }
    & { residenceTypes: Array<Maybe<(
      { __typename?: 'ResidenceType' }
      & Pick<ResidenceType, 'id'>
      & { names: (
        { __typename?: 'Names' }
        & Pick<Names, 'en' | 'ru'>
      ), descriptions: (
        { __typename?: 'Names' }
        & Pick<Names, 'en' | 'ru'>
      ), countryList: Array<Maybe<(
        { __typename?: 'CountryType' }
        & Pick<CountryType, 'code' | 'probability'>
        & { names: (
          { __typename?: 'Names' }
          & Pick<Names, 'en' | 'ru'>
        ) }
      )>> }
    )>> }
  ) }
);

export type PickQuizProgramMutationVariables = Exact<{
  programId: Scalars['String'];
}>;


export type PickQuizProgramMutation = (
  { __typename?: 'Mutation' }
  & { programPick: (
    { __typename?: 'ProgramPickMutation' }
    & Pick<ProgramPickMutation, 'status'>
  ) }
);

export type GetQuizFramesDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetQuizFramesDataQuery = (
  { __typename?: 'Query' }
  & { quiz: (
    { __typename?: 'Quiz' }
    & Pick<Quiz, 'framesData'>
  ) }
);

export type QuizSignUpMutationVariables = Exact<{
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  password: Scalars['String'];
}>;


export type QuizSignUpMutation = (
  { __typename?: 'Mutation' }
  & { quizSignup: (
    { __typename?: 'QuizSignUp' }
    & Pick<QuizSignUp, 'status'>
  ) }
);


export const ContactUsDocument = gql`
    mutation ContactUs($name: String!, $phone: String!, $email: String!, $note: String, $utmInfo: JSONString!, $additionalData: String) {
  createLead(
    name: $name
    email: $email
    phone: $phone
    note: $note
    utmInfo: $utmInfo
    additionalInfo: $additionalData
  ) {
    status
  }
}
    `;

export function useContactUsMutation() {
  return Urql.useMutation<ContactUsMutation, ContactUsMutationVariables>(ContactUsDocument);
};
export const CreateNewReviewDocument = gql`
    mutation CreateNewReview($rating: Int!, $name: String!, $reviewText: String!, $reviewType: String!) {
  createReview(
    rating: $rating
    name: $name
    reviewText: $reviewText
    reviewType: $reviewType
  ) {
    status
  }
}
    `;

export function useCreateNewReviewMutation() {
  return Urql.useMutation<CreateNewReviewMutation, CreateNewReviewMutationVariables>(CreateNewReviewDocument);
};
export const GetAllArticlesDocument = gql`
    query GetAllArticles($count: Int!, $offset: Int!) {
  allArticles(count: $count, offset: $offset) {
    slug
    picture
    title
    tags {
      code
    }
  }
  articlesCount
  tags {
    code
  }
}
    `;

export function useGetAllArticlesQuery(options: Omit<Urql.UseQueryArgs<GetAllArticlesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetAllArticlesQuery>({ query: GetAllArticlesDocument, ...options });
};
export const GetAllMediaDocument = gql`
    query getAllMedia {
  allMedia {
    id
    name
    icon
    link
  }
}
    `;

export function useGetAllMediaQuery(options: Omit<Urql.UseQueryArgs<GetAllMediaQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetAllMediaQuery>({ query: GetAllMediaDocument, ...options });
};
export const GetAllProgramsDocument = gql`
    query GetAllPrograms($lang: String!) {
  allPrograms(lang: $lang) {
    isRecommended
    slug
    programType
    country {
      names {
        en
        ru
      }
      code
    }
    terms
    difficulty
    shortDescription
    cardPicture
  }
}
    `;

export function useGetAllProgramsQuery(options: Omit<Urql.UseQueryArgs<GetAllProgramsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetAllProgramsQuery>({ query: GetAllProgramsDocument, ...options });
};
export const GetAllTagsDocument = gql`
    query getAllTags {
  tags {
    id
    code
  }
}
    `;

export function useGetAllTagsQuery(options: Omit<Urql.UseQueryArgs<GetAllTagsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetAllTagsQuery>({ query: GetAllTagsDocument, ...options });
};
export const GetArticleDocument = gql`
    query GetArticle($slug: String!) {
  article(slug: $slug) {
    htmlContent
    picture
    title
  }
}
    `;

export function useGetArticleQuery(options: Omit<Urql.UseQueryArgs<GetArticleQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetArticleQuery>({ query: GetArticleDocument, ...options });
};
export const GetEndscreenDocument = gql`
    query GetEndscreen($clickmeetingId: String!) {
  endscreen(clickmeetingId: $clickmeetingId) {
    id
    data
  }
}
    `;

export function useGetEndscreenQuery(options: Omit<Urql.UseQueryArgs<GetEndscreenQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetEndscreenQuery>({ query: GetEndscreenDocument, ...options });
};
export const GetLangDocument = gql`
    query GetLang($name: String!) {
  lang(name: $name) {
    name
    textsJson
  }
}
    `;

export function useGetLangQuery(options: Omit<Urql.UseQueryArgs<GetLangQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetLangQuery>({ query: GetLangDocument, ...options });
};
export const GetProgramDocument = gql`
    query GetProgram($slug: String!, $lang: String!) {
  program(slug: $slug, lang: $lang) {
    thumbnail
    picture
    programType
    country {
      code
      names {
        en
        ru
      }
    }
    shortDescription
    sameTypePrograms {
      slug
      country {
        code
        names {
          en
          ru
        }
      }
    }
    headings
    description
    advantagesJson
    resultDocument
    conditionsJson
    stepsJson
    faqJson
    otherPrograms {
      slug
      programType
      country {
        names {
          ru
          en
        }
      }
      terms
      difficulty
      shortDescription
      cardPicture
    }
  }
}
    `;

export function useGetProgramQuery(options: Omit<Urql.UseQueryArgs<GetProgramQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetProgramQuery>({ query: GetProgramDocument, ...options });
};
export const GetProgramNamesDocument = gql`
    query GetProgramNames($lang: String!) {
  allPrograms(lang: $lang) {
    slug
    programType
    country {
      names {
        en
        ru
      }
    }
  }
}
    `;

export function useGetProgramNamesQuery(options: Omit<Urql.UseQueryArgs<GetProgramNamesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetProgramNamesQuery>({ query: GetProgramNamesDocument, ...options });
};
export const GetReviewsDataDocument = gql`
    query GetReviewsData {
  allReviews {
    source
    writerName
    reviewType
    rating
    reviewText
    date
  }
}
    `;

export function useGetReviewsDataQuery(options: Omit<Urql.UseQueryArgs<GetReviewsDataQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetReviewsDataQuery>({ query: GetReviewsDataDocument, ...options });
};
export const GetTimeLeftDocument = gql`
    query GetTimeLeft {
  personalBonusTimeLeft
  globalBonusTimeLeft
}
    `;

export function useGetTimeLeftQuery(options: Omit<Urql.UseQueryArgs<GetTimeLeftQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetTimeLeftQuery>({ query: GetTimeLeftDocument, ...options });
};
export const IntensiveReferralSignupDocument = gql`
    mutation IntensiveReferralSignup($name: String!, $phone: String!, $email: String!) {
  intensiveSignup(name: $name, email: $email, phone: $phone) {
    status
  }
}
    `;

export function useIntensiveReferralSignupMutation() {
  return Urql.useMutation<IntensiveReferralSignupMutation, IntensiveReferralSignupMutationVariables>(IntensiveReferralSignupDocument);
};
export const WebinarSignupDocument = gql`
    mutation WebinarSignup($name: String!, $phone: String!, $email: String!, $note: String!, $utmInfo: JSONString!) {
  webinarSignup(
    clickmeetingId: 0
    name: $name
    email: $email
    phone: $phone
    note: $note
    utmInfo: $utmInfo
  ) {
    status
    attendeeId
  }
}
    `;

export function useWebinarSignupMutation() {
  return Urql.useMutation<WebinarSignupMutation, WebinarSignupMutationVariables>(WebinarSignupDocument);
};
export const GetClientPlanDocument = gql`
    query GetClientPlan {
  interactiveRoadmap {
    plan
  }
}
    `;

export function useGetClientPlanQuery(options: Omit<Urql.UseQueryArgs<GetClientPlanQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetClientPlanQuery>({ query: GetClientPlanDocument, ...options });
};
export const UpdateInteractiveRoadmapStepDocument = gql`
    mutation UpdateInteractiveRoadmapStep($newStepId: String!) {
  updateIrStep(newStepId: $newStepId) {
    status
  }
}
    `;

export function useUpdateInteractiveRoadmapStepMutation() {
  return Urql.useMutation<UpdateInteractiveRoadmapStepMutation, UpdateInteractiveRoadmapStepMutationVariables>(UpdateInteractiveRoadmapStepDocument);
};
export const GetCurrentRoadmapInfoDocument = gql`
    query GetCurrentRoadmapInfo {
  interactiveRoadmap {
    currentStepId
    roadmap
    programName
    plan
  }
}
    `;

export function useGetCurrentRoadmapInfoQuery(options: Omit<Urql.UseQueryArgs<GetCurrentRoadmapInfoQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetCurrentRoadmapInfoQuery>({ query: GetCurrentRoadmapInfoDocument, ...options });
};
export const GetQuizStepTimeLeftDocument = gql`
    query GetQuizStepTimeLeft {
  interactiveRoadmap {
    timeLeft
  }
}
    `;

export function useGetQuizStepTimeLeftQuery(options: Omit<Urql.UseQueryArgs<GetQuizStepTimeLeftQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetQuizStepTimeLeftQuery>({ query: GetQuizStepTimeLeftDocument, ...options });
};
export const CancelSubscriptionDocument = gql`
    mutation CancelSubscription {
  unsubscribe {
    status
  }
}
    `;

export function useCancelSubscriptionMutation() {
  return Urql.useMutation<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>(CancelSubscriptionDocument);
};
export const GetQuizUserNameDocument = gql`
    query GetQuizUserName {
  interactiveRoadmap {
    name
  }
}
    `;

export function useGetQuizUserNameQuery(options: Omit<Urql.UseQueryArgs<GetQuizUserNameQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetQuizUserNameQuery>({ query: GetQuizUserNameDocument, ...options });
};
export const GetReferrerCodeDocument = gql`
    mutation GetReferrerCode($email: String!, $name: String!, $phone: String!) {
  createReferralCode(name: $name, email: $email, phone: $phone) {
    code
    status
  }
}
    `;

export function useGetReferrerCodeMutation() {
  return Urql.useMutation<GetReferrerCodeMutation, GetReferrerCodeMutationVariables>(GetReferrerCodeDocument);
};
export const JobApplicationDocument = gql`
    mutation JobApplication($name: String!, $phone: String!, $email: String!, $link: String!, $job: String!) {
  jobApplication(
    name: $name
    email: $email
    phone: $phone
    job: $job
    link: $link
  ) {
    status
  }
}
    `;

export function useJobApplicationMutation() {
  return Urql.useMutation<JobApplicationMutation, JobApplicationMutationVariables>(JobApplicationDocument);
};
export const NewsletterSubscribeDocument = gql`
    mutation NewsletterSubscribe($email: String!) {
  newsSubscribe(email: $email) {
    status
  }
}
    `;

export function useNewsletterSubscribeMutation() {
  return Urql.useMutation<NewsletterSubscribeMutation, NewsletterSubscribeMutationVariables>(NewsletterSubscribeDocument);
};
export const ReceiveGamePrizeDocument = gql`
    mutation ReceiveGamePrize($name: String!, $phone: String!, $email: String!, $utmInfo: JSONString!) {
  receivePrize(name: $name, email: $email, phone: $phone, utmInfo: $utmInfo) {
    status
  }
}
    `;

export function useReceiveGamePrizeMutation() {
  return Urql.useMutation<ReceiveGamePrizeMutation, ReceiveGamePrizeMutationVariables>(ReceiveGamePrizeDocument);
};
export const GetHelpDocument = gql`
    mutation GetHelp($note: String!) {
  requestHelp(note: $note) {
    status
  }
}
    `;

export function useGetHelpMutation() {
  return Urql.useMutation<GetHelpMutation, GetHelpMutationVariables>(GetHelpDocument);
};
export const RequestPaymentDocument = gql`
    mutation RequestPayment($name: String!, $email: String!, $phone: String!, $purchaseType: String!, $method: String, $additionalInfo: JSONString) {
  createPurchase(
    name: $name
    email: $email
    phone: $phone
    purchaseType: $purchaseType
    method: $method
    additionalInfo: $additionalInfo
  ) {
    status
    sessionId
  }
}
    `;

export function useRequestPaymentMutation() {
  return Urql.useMutation<RequestPaymentMutation, RequestPaymentMutationVariables>(RequestPaymentDocument);
};
export const RequestRescheduleDocument = gql`
    mutation RequestReschedule($rescheduleHash: String!, $newTime: String!) {
  rescheduleConsultation(rescheduleHash: $rescheduleHash, selectedTime: $newTime) {
    status
  }
}
    `;

export function useRequestRescheduleMutation() {
  return Urql.useMutation<RequestRescheduleMutation, RequestRescheduleMutationVariables>(RequestRescheduleDocument);
};
export const SaveLangDocument = gql`
    mutation SaveLang($name: String!, $langChanges: String!) {
  updateLang(name: $name, langChanges: $langChanges) {
    allLangs {
      name
      textsJson
      editedAt
    }
  }
}
    `;

export function useSaveLangMutation() {
  return Urql.useMutation<SaveLangMutation, SaveLangMutationVariables>(SaveLangDocument);
};
export const ScheduleClientConsultationDocument = gql`
    mutation ScheduleClientConsultation($consultantId: String!, $selectedTime: String!) {
  scheduleConsultation(consultantId: $consultantId, selectedTime: $selectedTime) {
    status
  }
}
    `;

export function useScheduleClientConsultationMutation() {
  return Urql.useMutation<ScheduleClientConsultationMutation, ScheduleClientConsultationMutationVariables>(ScheduleClientConsultationDocument);
};
export const QuestionnaireUploadDocument = gql`
    mutation QuestionnaireUpload($consultationHash: String!, $applicants: String!, $description: String!, $termsFrom: String!, $termsTo: String!, $relocationGoal: String!, $questions: String!) {
  uploadQuestionnaire(
    consultationHash: $consultationHash
    applicants: $applicants
    description: $description
    questions: $questions
    relocationGoal: $relocationGoal
    termsFrom: $termsFrom
    termsTo: $termsTo
  ) {
    status
  }
}
    `;

export function useQuestionnaireUploadMutation() {
  return Urql.useMutation<QuestionnaireUploadMutation, QuestionnaireUploadMutationVariables>(QuestionnaireUploadDocument);
};
export const SetReminderTypeDocument = gql`
    mutation SetReminderType($attendeeId: String!, $reminderType: String!) {
  setReminderType(attendeeId: $attendeeId, reminderType: $reminderType) {
    status
  }
}
    `;

export function useSetReminderTypeMutation() {
  return Urql.useMutation<SetReminderTypeMutation, SetReminderTypeMutationVariables>(SetReminderTypeDocument);
};
export const UpdateSoloRoadmapStepDocument = gql`
    mutation UpdateSoloRoadmapStep($newStepId: String!) {
  updateStep(newStepId: $newStepId) {
    status
  }
}
    `;

export function useUpdateSoloRoadmapStepMutation() {
  return Urql.useMutation<UpdateSoloRoadmapStepMutation, UpdateSoloRoadmapStepMutationVariables>(UpdateSoloRoadmapStepDocument);
};
export const GetAllJobsDocument = gql`
    query getAllJobs {
  allJobs {
    title
    advantages
    shortDescription
    description
  }
}
    `;

export function useGetAllJobsQuery(options: Omit<Urql.UseQueryArgs<GetAllJobsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetAllJobsQuery>({ query: GetAllJobsDocument, ...options });
};
export const GetAllTextsDocument = gql`
    query GetAllTexts {
  allLangs {
    name
    textsJson
    editedAt
  }
}
    `;

export function useGetAllTextsQuery(options: Omit<Urql.UseQueryArgs<GetAllTextsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetAllTextsQuery>({ query: GetAllTextsDocument, ...options });
};
export const GetAvailableTimeDocument = gql`
    query GetAvailableTime {
  availableTime
}
    `;

export function useGetAvailableTimeQuery(options: Omit<Urql.UseQueryArgs<GetAvailableTimeQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetAvailableTimeQuery>({ query: GetAvailableTimeDocument, ...options });
};
export const GetClientCareManagerDocument = gql`
    query GetClientCareManager {
  careManager {
    id
    name
  }
}
    `;

export function useGetClientCareManagerQuery(options: Omit<Urql.UseQueryArgs<GetClientCareManagerQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetClientCareManagerQuery>({ query: GetClientCareManagerDocument, ...options });
};
export const GetClientRoadmapDocument = gql`
    query GetClientRoadmap {
  clientRoadmap {
    roadmap
    currentStep
    programName
    name
    workdriveLink
    clientHash
    reports {
      id
      date
    }
  }
}
    `;

export function useGetClientRoadmapQuery(options: Omit<Urql.UseQueryArgs<GetClientRoadmapQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetClientRoadmapQuery>({ query: GetClientRoadmapDocument, ...options });
};
export const GetClientWeeklyReportDocument = gql`
    query GetClientWeeklyReport($reportId: Int!) {
  report(reportId: $reportId) {
    date
    data
  }
}
    `;

export function useGetClientWeeklyReportQuery(options: Omit<Urql.UseQueryArgs<GetClientWeeklyReportQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetClientWeeklyReportQuery>({ query: GetClientWeeklyReportDocument, ...options });
};
export const GetConsultantTimesheetDocument = gql`
    query GetConsultantTimesheet($consultantId: String!) {
  availableTime(consultantIds: [$consultantId])
}
    `;

export function useGetConsultantTimesheetQuery(options: Omit<Urql.UseQueryArgs<GetConsultantTimesheetQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetConsultantTimesheetQuery>({ query: GetConsultantTimesheetDocument, ...options });
};
export const GetGamePrizeDocument = gql`
    query GetGamePrize {
  gamePrize
}
    `;

export function useGetGamePrizeQuery(options: Omit<Urql.UseQueryArgs<GetGamePrizeQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetGamePrizeQuery>({ query: GetGamePrizeDocument, ...options });
};
export const GetSoloRoadmapDocument = gql`
    query GetSoloRoadmap {
  soloRoadmap {
    name
    programName
    roadmap
    currentStepId
  }
}
    `;

export function useGetSoloRoadmapQuery(options: Omit<Urql.UseQueryArgs<GetSoloRoadmapQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetSoloRoadmapQuery>({ query: GetSoloRoadmapDocument, ...options });
};
export const LoginDocument = gql`
    query Login($username: String!, $password: String!) {
  login(username: $username, password: $password) {
    token
  }
  roadmapType(username: $username)
}
    `;

export function useLoginQuery(options: Omit<Urql.UseQueryArgs<LoginQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<LoginQuery>({ query: LoginDocument, ...options });
};
export const WinGamePrizeDocument = gql`
    query WinGamePrize {
  openGamePrize
}
    `;

export function useWinGamePrizeQuery(options: Omit<Urql.UseQueryArgs<WinGamePrizeQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<WinGamePrizeQuery>({ query: WinGamePrizeDocument, ...options });
};
export const GetDirectionsDocument = gql`
    query GetDirections($criteriaIds: [String]!) {
  quiz {
    directions {
      id
      names {
        en
        ru
      }
      descriptions {
        en
        ru
      }
      probability(criteriaIds: $criteriaIds)
    }
  }
}
    `;

export function useGetDirectionsQuery(options: Omit<Urql.UseQueryArgs<GetDirectionsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetDirectionsQuery>({ query: GetDirectionsDocument, ...options });
};
export const GetQuizProgramDocument = gql`
    query GetQuizProgram($programId: String!) {
  quiz {
    program(programId: $programId) {
      name
      data
    }
  }
}
    `;

export function useGetQuizProgramQuery(options: Omit<Urql.UseQueryArgs<GetQuizProgramQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetQuizProgramQuery>({ query: GetQuizProgramDocument, ...options });
};
export const GetQuizProgramDataDocument = gql`
    query GetQuizProgramData($programId: String!) {
  quiz {
    program(programId: $programId) {
      data
      name
    }
  }
}
    `;

export function useGetQuizProgramDataQuery(options: Omit<Urql.UseQueryArgs<GetQuizProgramDataQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetQuizProgramDataQuery>({ query: GetQuizProgramDataDocument, ...options });
};
export const GetQuizProgramsDocument = gql`
    query GetQuizPrograms($locale: String!, $residenceTypeIds: [String]!, $selectedCountryCodes: [String]!) {
  quiz {
    programs(
      locale: $locale
      residenceTypeIds: $residenceTypeIds
      selectedCountryCodes: $selectedCountryCodes
    ) {
      id
      name
      directionId
    }
  }
}
    `;

export function useGetQuizProgramsQuery(options: Omit<Urql.UseQueryArgs<GetQuizProgramsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetQuizProgramsQuery>({ query: GetQuizProgramsDocument, ...options });
};
export const GetResidenceTypesDocument = gql`
    query GetResidenceTypes($selectedDirections: [String]!) {
  quiz {
    residenceTypes(selectedDirections: $selectedDirections) {
      id
      names {
        en
        ru
      }
      descriptions {
        en
        ru
      }
      countryList {
        code
        names {
          en
          ru
        }
        probability
      }
    }
  }
}
    `;

export function useGetResidenceTypesQuery(options: Omit<Urql.UseQueryArgs<GetResidenceTypesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetResidenceTypesQuery>({ query: GetResidenceTypesDocument, ...options });
};
export const PickQuizProgramDocument = gql`
    mutation PickQuizProgram($programId: String!) {
  programPick(programId: $programId) {
    status
  }
}
    `;

export function usePickQuizProgramMutation() {
  return Urql.useMutation<PickQuizProgramMutation, PickQuizProgramMutationVariables>(PickQuizProgramDocument);
};
export const GetQuizFramesDataDocument = gql`
    query GetQuizFramesData {
  quiz {
    framesData
  }
}
    `;

export function useGetQuizFramesDataQuery(options: Omit<Urql.UseQueryArgs<GetQuizFramesDataQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetQuizFramesDataQuery>({ query: GetQuizFramesDataDocument, ...options });
};
export const QuizSignUpDocument = gql`
    mutation QuizSignUp($email: String!, $name: String!, $phone: String!, $password: String!) {
  quizSignup(email: $email, name: $name, phone: $phone, password: $password) {
    status
  }
}
    `;

export function useQuizSignUpMutation() {
  return Urql.useMutation<QuizSignUpMutation, QuizSignUpMutationVariables>(QuizSignUpDocument);
};