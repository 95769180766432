import {
  ReactChild,
  ButtonHTMLAttributes,
  AnchorHTMLAttributes,
  useContext
} from 'react'
import { LocationDescriptor } from 'history'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { motion, MotionProps } from 'framer-motion'

import styles from './FlatButton.module.scss'

import { ReactComponent as LoadingSVG } from './loading.svg'
import { LanguageContext } from 'components'

type ButtonProps = {
  loading?: boolean
  disabled?: boolean
  color: 'purple' | 'orange' | 'accentBlue' | 'accentCyan'
  redirectLocal?: LocationDescriptor
  redirectProps?: AnchorHTMLAttributes<HTMLAnchorElement>
  children: ReactChild | ReactChild[]
  buttonProps?: ButtonHTMLAttributes<HTMLButtonElement> & MotionProps
}

export const FlatButton = (props: ButtonProps) => {
  const { disabled, loading, redirectLocal, redirectProps, buttonProps } = props

  const history = useHistory()
  const [currentLocale] = useContext(LanguageContext)

  return (
    <motion.button
      {...props.buttonProps}
      className={classNames(
        buttonProps?.className,
        styles.box,
        styles[props.color],
        {
          [styles.loading]: loading
        }
      )}
      onClick={e => {
        if (redirectProps && !loading) {
          setTimeout(() => {
            const redirectAnchor = document.createElement('a')
            Object.keys(redirectProps).forEach(key => {
              redirectAnchor[key] = redirectProps[key]
            })
            redirectAnchor.click()
          }, 300)
        }
        if (redirectLocal && !loading) {
          const redirectUrl =
            currentLocale === 'en' ? `/en${redirectLocal}` : redirectLocal
          setTimeout(() => history.push(redirectUrl), 200)
        }
        if (!loading) {
          setTimeout(
            () => {
              if (buttonProps?.onClick) buttonProps.onClick(e)
            },
            !redirectLocal && !redirectProps ? 200 : 10 // perform click event instantly only when button acts as a link
          )
        }
      }}
      disabled={disabled}
    >
      <span
        className={styles.content}
        style={{
          opacity: loading ? 0 : 1
        }}
      >
        {props.children}
      </span>
      <div className={styles.loader}>
        <LoadingSVG />
      </div>
      <div className={styles.button} />
    </motion.button>
  )
}
