import lazy from 'react-lazy-named'

export const EditTexts = lazy(() => import('./texts/EditTexts'), 'EditTexts')

export { LanguageProvider, LanguageContext } from './texts/LanguageProvider'
export { CatProvider, CatContext } from './cat/CatProvider'
export { PopupProvider, PopupContext } from './popup/Popup'
export { UtmProvider, UtmContext } from './utmProvider/UtmProvider'

export { GenericForm } from './genericForm/GenericForm'
export { YandexMetrika } from './YandexMetrika'
export { ScrollTop } from './scrollTop/ScrollTop'
export { MobileMenu } from './mobileMenu/MobileMenu'

export { Header } from './header/Header'
export { Footer } from './footer/Footer'