import { useCallback, useEffect, useRef, useState } from 'react'
import { default as yandexMetrika } from 'react-yandex-metrika'

// returns true if app runs in development environment and false if in production
export const isDev = process.env.NODE_ENV === 'development'
export const isDuplicate = window.location.host === 'how2relocate.com'

// returns true if obj1 is equal to obj2 (key order matters!)
export const objectsMatch = (ob1: object, obj2: object) =>
  JSON.stringify(ob1) === JSON.stringify(obj2)

// range(4) returns [0, 1, 2, 3]
export const range = (length: number) => Array.from(Array(length).keys())

// run ym methods only in production
export const ym = (methodName: string, ...args: any[]) => {
  if (!isDev) {
    yandexMetrika(methodName, ...args)
  }
}

interface EventParams extends Gtag.EventParams {
  event_action?: string
}

// run ga events only in production
export const gtagEvent = (args: EventParams | Gtag.ControlParams) => {
  if (!isDev) {
    window.gtag('event', args['event_category'], args)
  }
}

// extracts Array from Array-like objects
export const extractArray = (source: any) => {
  if (!source) return
  const output: Array<any> = []
  Object.entries(source).forEach(([key, val]) => {
    if (/^\+?(0|[1-9]\d*)$/.test(key)) output.push(val)
  })
  return output
}

type ParseTagsValues = {
  [tag: string]: (children: ChildrenArray | string, key: number) => JSX.Element
}
type RegexMap = { [tag: string]: RegExp }
type OutputValue = string | JSX.Element
type ChildrenArray = OutputValue[]

// parses HTML tags
export const parseTags = (
  input: string,
  params: { additionalRegexMaps?: RegexMap; values: ParseTagsValues }
) => {
  const { values, additionalRegexMaps } = params

  const regexMap: RegexMap = {}
  Object.keys(values).forEach(tag => {
    regexMap[tag] = new RegExp(`<${tag}>([\\s\\S]*?)<\\/${tag}>`, 'gmu')
  })
  if (additionalRegexMaps) {
    Object.assign(regexMap, additionalRegexMaps)
  }

  const findRegex = (
    input: string
  ): [RegExpExecArray, string] | [null, null] => {
    for (const [tag, regex] of Object.entries(regexMap)) {
      const regexResult = regex.exec(input)
      if (regexResult) return [regexResult, tag]
    }
    return [null, null]
  }

  let key = 0

  const processInput = (
    input: string,
    applyTag: string | null
  ): ChildrenArray | OutputValue => {
    const [regexResult, tag] = findRegex(input)
    let output: ChildrenArray | OutputValue

    const matches = extractArray(regexResult)
    if (matches && regexResult) {
      const pre = processInput(input.substring(0, regexResult.index), null)
      const post = processInput(
        input.substring(
          regexResult.index + regexResult[0].length,
          input.length + 1
        ),
        null
      )
      output = [
        ...(Array.isArray(pre) ? pre : [pre]),
        ...(matches.length > 1
          ? matches
              .slice(1)
              .map(match => processInput(match, tag) as OutputValue)
          : [values[tag as string](matches[0], ++key)]),
        ...(Array.isArray(post) ? post : [post])
      ]
    } else {
      output = input
    }
    return applyTag ? values[applyTag](output, ++key) : output
  }

  return processInput(input, null)
}

// custom hook to use requestAnimationFrame
export const useAnimationFrame = (callback: (deltaTime: number) => void) => {
  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestRef = useRef(0)
  const previousTimeRef = useRef(0)

  const animate = useCallback(
    (time: number) => {
      if (previousTimeRef.current) {
        const deltaTime = time - previousTimeRef.current
        callback(deltaTime)
      }
      previousTimeRef.current = time
      requestRef.current = requestAnimationFrame(animate)
    },
    [callback]
  )

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate)
    return () => cancelAnimationFrame(requestRef.current)
  }, [animate])
}

// custom hook to use media queries
export function useMediaQuery(query: string) {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const media = window.matchMedia(query)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }
    const listener = () => {
      setMatches(media.matches)
    }
    media.addEventListener('change', listener)
    return () => media.removeEventListener('change', listener)
  }, [matches, query])

  return matches
}

// cookies parser
export const getCookie = (name: string) => {
  let cookieValue = null
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim()
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
        break
      }
    }
  }
  return cookieValue
}

export const isLoggedIn = () => !!localStorage.jwt

// wrapper for localStorage.getItem() that always returns string
export const localStorageGetItem = (key: string) => {
  const value = window.localStorage.getItem(key)
  return value ? value : ''
}

// returns stored user info
export const getStoredUserData = () => ({
  email: localStorageGetItem('email'),
  phone: localStorageGetItem('phone'),
  name: localStorageGetItem('name'),
  registrantId: localStorageGetItem('registrantId')
})

// pass formik's values to this function
export const saveUserData = (data: object) => {
  ['email', 'phone', 'name'].forEach(key => {
    if (key in data) window.localStorage.setItem(key, data[key])
  })
}

export const stripeKey =
  window.location.hostname === 'hello-move.com'
    ? 'pk_live_HIZ16LnT5fYmVsJcafgEewk6008RfwT4gq'
    : 'pk_test_L4SeqfD9uSNi68KoLjILEygv00iPv6qgWK'
