import { FC, Suspense} from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import {
  EditTexts,
  YandexMetrika,
  UtmProvider,
  //Header,
  // MobileMenu,
  // Footer,
  ScrollTop,
  GenericForm,
  PopupProvider,
  PopupContext,
  CatProvider,
  CatContext,
  LanguageProvider,
  LanguageContext,
  UtmContext
} from 'components/legacy/utility'

export const utilityRoutes = [
  { exact: true, path: '/edit_texts', component: EditTexts }
]

export {
  CatContext,
  LanguageProvider,
  LanguageContext,
  PopupContext,
  UtmContext,
  GenericForm
}

export const UtilityWrapper: FC = props => {
  // const navHide = [
  //   /\/residence/,
  //   /\/platform.*/,
  //   /\/webinar.*/,
  //   /\/intensive.*/,
  //   /\/marathon.*/,
  //   /\/endscreen.*/
  // ]
  return (
    <Router>
      <UtmProvider>
        <PopupProvider>
          <CatProvider>
            {/*<Header hidePaths={navHide} />*/}
            <div className="content">
              <Suspense fallback={null}>
                <Switch>{props.children}</Switch>
              </Suspense>
              <YandexMetrika />
            </div>
            {/*<Footer />*/}
            <ScrollTop />
          </CatProvider>
        </PopupProvider>
      </UtmProvider>
    </Router>
  )
}
