import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useFormik } from 'formik'
import classNames from 'classnames'
import { getStoredUserData, gtagEvent, ym } from 'utils'
import { Button } from 'elements'

import styles from './css/LoginForm.module.scss'

import { ReactComponent as SuccessSVG } from './media/success.svg'
import { makeQuery } from 'api/makeQuery'
import { LoginDocument, LoginQuery, QueryLoginArgs } from 'generated/graphql'
import { useHistory } from 'react-router-dom'

type FormValues = {
  email?: string
  password?: string
}

type FormProps = {
  closePopup: () => void
  callback?: () => void
}

export const LoginForm = (props: FormProps) => {
  const intl = useIntl()
  const history = useHistory()

  useEffect(() => {
    gtagEvent({
      event_category: 'authorization',
      event_action: 'form_open'
    })
    ym('reachGoal', 'authorization_open')
  }, [])

  const initialValues = {
    email: getStoredUserData().email,
    password: ''
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async values => {
      setResult('loading')
      const response = await makeQuery<LoginQuery, QueryLoginArgs>({
        type: 'query',
        gqlQuery: LoginDocument,
        variables: {
          username: values.email,
          password: values.password
        }
      })
      if (response.data?.login.token) {
        window.localStorage.setItem('jwt', response.data.login.token)
        setResult('success')
        if (props.callback) props.callback()
        history.push(response.data.roadmapType)
      } else {
        formik.errors.email = intl.formatMessage({
          id: 'clientRoadmap.loginForm.invalidEmailOrPassword',
          defaultMessage: 'Неверный e-mail или пароль'
        })
      }
    },
    validate: values => {
      const { email, password } = values
      const errors: FormValues = {}
      const re =
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/

      if (!re.test(email.toLowerCase()))
        errors.email = intl.formatMessage({
          id: 'clientRoadmap.loginForm.invalidEmail',
          defaultMessage: 'Неверный формат почтового адреса'
        })

      if (password.length < 8)
        errors.password = intl.formatMessage({
          id: 'clientRoadmap.loginForm.shortPassword',
          defaultMessage: 'Слишком короткий пароль'
        })
      return errors
    }
  })

  const [result, setResult] = useState<undefined | string>(undefined)

  return (
    <div
      className={classNames(styles.card, {
        [styles.success]: result === 'success'
      })}
    >
      <h4>
        <FormattedMessage
          id="clientRoadmap.loginForm.heading"
          defaultMessage="Вход в аккаунт"
        />
      </h4>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.fields}>
          {'email' in formik.touched && formik.errors.email && (
            <div className={styles.error}>{formik.errors.email}</div>
          )}
          <input
            name="email"
            type="email"
            placeholder={intl.formatMessage({
              id: 'clientRoadmap.loginForm.emailPlaceholder',
              defaultMessage: 'E-mail*'
            })}
            onChange={formik.handleChange}
            onFocus={e => {
              e.currentTarget.select()
            }}
            value={formik.values.email}
          />
          {'password' in formik.touched && formik.errors.password && (
            <div className={styles.error}>{formik.errors.password}</div>
          )}
          <input
            name="password"
            type="password"
            placeholder={intl.formatMessage({
              id: 'clientRoadmap.loginForm.passwordPlaceholder',
              defaultMessage: 'Пароль*'
            })}
            onChange={formik.handleChange}
            onFocus={e => {
              e.currentTarget.select()
            }}
            value={formik.values.password}
          />
        </div>
        <Button
          color="basic"
          loading={result === 'loading'}
          disabled={!(formik.dirty && formik.isValid)}
          buttonProps={{
            type: 'submit'
          }}
        >
          <FormattedMessage
            id="clientRoadmap.loginForm.button"
            defaultMessage="Войти"
          />
        </Button>
        <div className={styles.note}>
          <FormattedMessage
            id="clientRoadmap.loginForm.note"
            defaultMessage="Если у Вас ещё нет доступа, запросите его у персонального менеджера"
          />
        </div>
      </form>
      <div className={styles.sent}>
        <SuccessSVG className={styles.successSVG} />
        <p>
          {result === 'success' && (
            <FormattedMessage
              id="clientRoadmap.loginForm.success"
              defaultMessage="Вы успешно вошли! Надеемся, что у вас все хорошо, а когда мы закончим процесс, будет ещё лучше!"
            />
          )}
        </p>
      </div>
    </div>
  )
}
