import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'urql'
import { LanguageProvider } from 'components'
import { client } from 'api/makeQuery'
import { isDev } from 'utils'

import reportWebVitals from './reportWebVitals'
// import ChangeLanguageModal from 'elements/change-language-modal/ChangeLanguageModal'

function render() {
  const App = require('app/App').default
  ReactDOM.render(
    <React.StrictMode>
      <Provider value={client}>
        <LanguageProvider>
          {/*<ChangeLanguageModal/>*/}
          <App />
        </LanguageProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  )
}

render()

if (isDev && module.hot) {
  module.hot.accept('app/App', render)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
