import { createContext, FC, useCallback, useEffect, useState } from 'react'
import { isDev } from 'utils'
import { useHistory } from 'react-router-dom'

export const UtmContext = createContext<() => object>(() => ({}))

export const UtmProvider: FC = props => {
  const [utmData, setUtmData] = useState({})

  const history = useHistory()
  useEffect(() => {
    const params = new URL(window.location.toString()).searchParams
    const utm = {}
    params.forEach((val, key) => {
      utm[key] = val
    })
    utm['Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone
    utm['Page_Pathname'] = window.location.pathname
    setUtmData(utm)

    const unListen = history.listen(location => {
      if (!isDev) {
        setUtmData(prevState => ({
          ...prevState,
          Page_Pathname: location.pathname
        }))
        gtag('config', 'UA-154566426-2', {
          page_path: location.pathname
        })
      }
    })

    return () => unListen()
  }, [history])

  const getUtmData = useCallback(() => utmData, [utmData])
  return (
    <UtmContext.Provider value={getUtmData}>
      {props.children}
    </UtmContext.Provider>
  )
}
