import { createContext, FC, useCallback, useState } from 'react'
import { Cat } from './Cat'
import { Helmet } from 'react-helmet-async'

const initialCatState =
  'cat' in window.localStorage ? localStorage.getItem('cat') : 'disabled'

export const CatContext = createContext<[string | null, () => void]>([
  initialCatState,
  () => {}
])

export const CatProvider: FC = ({ children }) => {
  const [catState, setCatState] = useState(initialCatState)
  const toggleCat = useCallback(() => {
    const state = catState === 'enabled' ? 'disabled' : 'enabled'
    window.localStorage.setItem('cat', state)
    setCatState(state)
  }, [catState, setCatState])

  return (
    <>
      <Helmet>
        <link
          rel="icon"
          href={catState === 'enabled' ? '/favicon_dream.ico' : '/favicon.ico'}
        />
      </Helmet>
      <CatContext.Provider value={[catState, toggleCat]}>
        {children}
        {catState === 'enabled' && <Cat />}
      </CatContext.Provider>
    </>
  )
}
