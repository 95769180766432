import { Client } from '@urql/core'
import { getCookie, isDev } from 'utils'
import { DocumentNode, GraphQLError } from 'graphql'
import { OperationResult } from 'urql'
import { OperationContext } from '@urql/core/dist/types/types'

export type apiOptions<Args> = {
  type: 'query' | 'mutation'
  gqlQuery: DocumentNode
  variables?: Args
  context?: Partial<OperationContext>
}

const buildFetchOptions = (
  fetchOptions?: RequestInit | (() => RequestInit)
): RequestInit => {
  const csrftoken = getCookie('csrftoken')
  const baseHeaders = {}
  if (csrftoken) {
    baseHeaders['X-CSRFToken'] = csrftoken
  }
  const fetchOptionsObject =
    typeof fetchOptions === 'function' ? fetchOptions() : fetchOptions
  if (fetchOptionsObject) {
    return {
      ...fetchOptionsObject,
      headers: {
        ...fetchOptionsObject.headers,
        ...baseHeaders
      }
    }
  }
  return {
    headers: baseHeaders
  }
}

export const client = new Client({
  url: isDev
    ? `${window.location.protocol}//${window.location.hostname}:8000/graphql`
    : `${window.location.protocol}//${window.location.host}/graphql`,
  fetchOptions: buildFetchOptions()
})

export const makeQuery = async <Query, Args extends object = {}>(
  options: apiOptions<Args>
): Promise<OperationResult<Query, Args>> => {
  const { type, gqlQuery, variables, context } = options
  // execute request depending on the specified type
  const result = await client[type]<Query, Args>(gqlQuery, variables, {
    ...context,
    fetchOptions: buildFetchOptions(context?.fetchOptions)
  }).toPromise()
  if (result.error) {
    // if server returns error message then throw as actual GraphQL error
    throw new GraphQLError(result.error.message)
  } else {
    return result
  }
}
