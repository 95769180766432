import {
  useCallback,
  useEffect,
  useRef,
  useState,
  ReactChild,
  MouseEvent as ReactMouseEvent,
  ButtonHTMLAttributes,
  AnchorHTMLAttributes,
  useContext
} from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { LocationDescriptor } from 'history'

import styles from './Button.module.scss'

import { ReactComponent as LoadingSVG } from './loading.svg'
import { LanguageContext } from 'components'

const isMouseInRect = (e: MouseEvent, rect: DOMRect | undefined): boolean => {
  if (!rect) return false

  const { clientX: mouseX, clientY: mouseY } = e
  const { top, bottom, left, right } = rect
  return mouseX >= left && mouseX < right && mouseY >= top && mouseY < bottom
}

type ButtonProps = {
  color: 'accent' | 'basic'
  loading?: boolean
  disabled?: boolean
  redirectLocal?: LocationDescriptor
  redirectProps?: AnchorHTMLAttributes<HTMLAnchorElement>
  children: ReactChild | ReactChild[]
  buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>
}

export const Button = (props: ButtonProps) => {
  const [currentLocale] = useContext(LanguageContext)

  const {
    color,
    disabled,
    loading,
    redirectLocal,
    redirectProps,
    buttonProps
  } = props

  const hoverboard = useRef<HTMLDivElement>(null)
  const shade = useRef<HTMLDivElement>(null)
  const [shadeVisible, setShadeVisible] = useState(false)

  const onHover = (e: MouseEvent) => {
    if (!shade.current || !hoverboard.current) return

    const { x: hoverboardOffsetX, y: hoverboardOffsetY } =
      hoverboard.current.getBoundingClientRect()
    const { clientX: mouseX, clientY: mouseY } = e

    //  shade's width (& height) / 2 = 150px
    const shadeX = mouseX - hoverboardOffsetX - 150
    const shadeY = mouseY - hoverboardOffsetY - 150

    shade.current.style.transform = `translate(${shadeX}px, ${shadeY}px)`
  }
  const showShade = useCallback(
    (e: MouseEvent) => {
      if (
        !shadeVisible &&
        isMouseInRect(e, hoverboard.current?.getBoundingClientRect())
      ) {
        setShadeVisible(true)
        onHover(e)
      }
    },
    [shadeVisible]
  )
  const hideShade = useCallback(
    (e: MouseEvent) => {
      if (
        shadeVisible &&
        !isMouseInRect(e, hoverboard.current?.getBoundingClientRect())
      ) {
        setShadeVisible(false)
      }
    },
    [shadeVisible]
  )
  const removeShade = () => {
    hoverboard.current?.remove()
  }
  useEffect(() => {
    if (!disabled) {
      window.addEventListener('mousemove', onHover)
      window.addEventListener('mousemove', showShade)
      window.addEventListener('mousemove', hideShade)
      window.addEventListener('touchstart', removeShade)
    }
    // cleanup

    return () => {
      window.removeEventListener('mousemove', onHover)
      window.removeEventListener('mousemove', showShade)
      window.removeEventListener('mousemove', hideShade)
      window.removeEventListener('touchstart', removeShade)
    }
  }, [showShade, hideShade, disabled])

  const rippleBox = useRef<HTMLDivElement>(null)
  const ripple = useRef<HTMLDivElement>(null)
  const [active, setActive] = useState(false)
  const getRippleDiameter = () =>
    rippleBox.current
      ? rippleBox.current.getBoundingClientRect().width / 2
      : 200

  const spawnRipple = (e: ReactMouseEvent) => {
    setActive(true)

    if (!rippleBox.current || !ripple.current) return
    const {
      x: rippleBoxOffsetX,
      y: rippleBoxOffsetY,
      width
    } = rippleBox.current.getBoundingClientRect()

    const { clientX: mouseX, clientY: mouseY } = e

    const rippleRadius = getRippleDiameter() / 2
    const rippleX = mouseX - rippleBoxOffsetX - rippleRadius
    const rippleY = mouseY - rippleBoxOffsetY - rippleRadius

    // distance to the center of a rippleBox (0 in the center, width / 2 on the edges)
    const offCenterDistance = Math.abs(mouseX - rippleBoxOffsetX - width / 2)
    // 0.5 if distance is 0, 1 if distance is max (on the edges)
    const scaleMultiplier = 0.5 + offCenterDistance / width

    ripple.current.style.left = `${rippleX}px`
    ripple.current.style.setProperty(
      '--scaleMultiplier',
      scaleMultiplier.toString()
    )
    ripple.current.style.top = `${rippleY}px`
  }
  const removeRipple = () => {
    // wait for animation to finish
    setTimeout(() => {
      setActive(false)
    }, 500)
  }

  const history = useHistory()

  return (
    <button
      {...props.buttonProps}
      className={classNames(
        buttonProps?.className,
        styles.button,
        styles[color],
        {
          [styles.active]: active,
          [styles.loading]: loading
        }
      )}
      onClick={e => {
        if (redirectProps && !loading) {
          setTimeout(() => {
            const redirectAnchor = document.createElement('a')
            Object.keys(redirectProps).forEach(key => {
              redirectAnchor[key] = redirectProps[key]
            })
            redirectAnchor.click()
          }, 300)
        }

        if (redirectLocal && !loading) {
          const redirectUrl =
            currentLocale === 'en' ? `/en${redirectLocal}` : redirectLocal
          setTimeout(() => history.push(redirectUrl), 300)
        }

        if (!loading) {
          setTimeout(
            () => {
              if (buttonProps?.onClick) buttonProps.onClick(e)
            },
            !redirectLocal && !redirectProps ? 300 : 10 // perform click event instantly only when button acts as a link
          )
        }
      }}
      disabled={disabled}
      onMouseDown={spawnRipple}
      onMouseUp={removeRipple}
      onMouseLeave={() => setActive(false)}
      onTouchStart={() => hoverboard.current?.remove()} // remove shade effect on touch devices
    >
      <div ref={hoverboard} className={styles.hoverboard}>
        {shadeVisible && <div ref={shade} className={styles.shade} />}
      </div>
      <div ref={rippleBox} className={styles.rippleBox}>
        <div
          ref={ripple}
          style={{
            width: getRippleDiameter(),
            height: getRippleDiameter()
          }}
          className={classNames(styles.ripple, {
            [styles.rippleVisible]: active
          })}
        />
      </div>
      <span
        style={{
          opacity: loading ? 0 : 1
        }}
      >
        {props.children}
      </span>
      <div className={styles.loader}>
        <LoadingSVG />
      </div>
    </button>
  )
}
