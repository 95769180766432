import {
  Contacts,
  FAQ,
  // GuaranteesPage,
  LegalInfo,
  NotFound,
  Requisites,
  //ReviewsScreen,
  SpecialOffer,
  VacanciesPage
} from 'components/legacy/other'
import {
  // Contacts
  // FAQ,
  GuaranteesPage,
  // LegalInfo,
  // NotFound,
  // Requisites,
  ResidencePage,
  // SpecialOffer,
  // VacanciesPage,
  LoyaltyProgram,
  ItalyLanding,
  Login,
  Game
} from 'components/current/other'

export const otherRoutes = [
  { exact: true, path: '/game', component: Game },
  { exact: true, path: '/italy', component: ItalyLanding },
  { exact: true, path: '/login', component: Login },
  { exact: true, path: '/refer', component: LoyaltyProgram },
  { exact: true, path: '/contacts', component: Contacts },
  { exact: true, path: '/faq', component: FAQ },
  { exact: true, path: '/residence', component: ResidencePage },
  { exact: true, path: '/guarantees', component: GuaranteesPage },
  { exact: true, path: '/jobs', component: VacanciesPage },
  { exact: true, path: '/legal_info/requisites', component: Requisites },
  { exact: true, path: '/sale', component: SpecialOffer },
  { path: '/legal_info', component: LegalInfo },
  { path: '*', component: NotFound }
]
