import lazy from 'react-lazy-named'

export const Contacts = lazy(() => import('./contacts/Contacts'), 'Contacts')
export const FAQ = lazy(() => import('./faq/FAQ'), 'FAQ')
export const NotFound = lazy(() => import('./notFoundPage/NotFound'), 'NotFound')
export const LegalInfo = lazy(() => import('./legalInfo/LegalInfo'), 'LegalInfo')
export const Requisites = lazy(() => import('./legalInfo/Requisites'), 'Requisites')
export const ResidencePage = lazy(() => import('./residencePage/ResidencePage'), 'ResidencePage')
export const SpecialOffer = lazy(() => import('./specialOffer/SpecialOffer'), 'SpecialOffer')
export const GuaranteesPage = lazy(() => import('./guarantees/GuaranteesPage'), 'GuaranteesPage')
export const VacanciesPage = lazy(() => import('./vacanciesPage/VacanciesPage'), 'VacanciesPage')