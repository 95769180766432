import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import { useState } from 'react'
import 'moment/locale/ru'
import { useContext } from 'react'
import { LanguageContext } from 'components'

import styles from './Calendar.module.scss'

const moment = require('moment-timezone')

type CalendarProps = {
  availableDates: Date[]
  selectedDate?: Date
  onDateSelect: (selectedDate: Date) => void
}

export const Calendar = (props: CalendarProps) => {
  const { availableDates, onDateSelect } = props
  const [selectedDate, setSelectedDate] = useState<Date>()
  const dateClickHandler = (day: string, month: number, year: number) => {
    setSelectedDate(new Date(year, month, parseInt(day)))
    onDateSelect(new Date(year, month, parseInt(day)))
  }

  const [currentLocale] = useContext(LanguageContext)
  moment.locale(currentLocale)
  const currentTimezone = 'Europe/Moscow'

  const thisMonthFirstDay = new Date(
    new Date().getFullYear(),
    new Date().getMonth()
  )

  // date for flipping calendar. value is first day in month
  const [utilityDate, setUtilityDate] = useState(thisMonthFirstDay)

  const month = new Intl.DateTimeFormat('ru', { month: 'long' }).format(
    utilityDate
  )
  const monthFormatted = month[0].toUpperCase() + month.slice(1)

  const year = utilityDate.getFullYear()

  const weekdayNames = []

  for (let i = 0; i < 7; i++) {
    weekdayNames[i] = new Intl.DateTimeFormat('ru', { weekday: 'short' })
      .format((i + 4) * 24 * 3600 * 1000)
      .toUpperCase()
  }

  let firstDayMonthWeekday = utilityDate.getDay()
  if (firstDayMonthWeekday === 0) firstDayMonthWeekday = 7

  const monthNumber = utilityDate.getMonth() + 1

  const daysCountInMonth =
    28 +
    ((monthNumber + Math.floor(monthNumber / 8)) % 2) +
    (2 % monthNumber) +
    2 * Math.floor(1 / monthNumber)

  let monthArr = [[''], [''], [''], [''], [''], ['']]

  let day = 1
  for (let row = 0; row < 6; row++) {
    for (let col = 0; col < 7; col++) {
      if (row === 0 && col < firstDayMonthWeekday - 1) {
        monthArr[row][col] = ''
      } else if (day <= daysCountInMonth) {
        monthArr[row][col] = day.toString()
        day++
      }
    }
  }

  const availableDaysThisMonth = availableDates
    .filter(date => date.getMonth() === utilityDate.getMonth())
    .map(date => date.getDate())

  return (
    <section>
      <div className={styles.arrowMonthYearContainer}>
        <button
          className={styles.arrowPrevNext}
          onClick={() =>
            setUtilityDate(
              prev => new Date(prev.getFullYear(), prev.getMonth() - 1, 1)
            )
          }
        >
          &lt;
        </button>
        <span className={styles.monthYear}>
          {monthFormatted} {year}
        </span>
        <button
          className={styles.arrowPrevNext}
          onClick={() =>
            setUtilityDate(
              prev => new Date(prev.getFullYear(), prev.getMonth() + 1, 1)
            )
          }
        >
          &gt;
        </button>
      </div>

      <table className={styles.table}>
        <thead>
          <tr>
            {weekdayNames.map(day => (
              <th key={day} className={styles.weekday}>
                {day}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {monthArr.map((row, index) => {
            return (
              <tr key={index}>
                {row.map((day, index) => {
                  const available = !!availableDaysThisMonth.includes(
                    parseInt(day)
                  )
                  const selected =
                    selectedDate &&
                    parseInt(day) === selectedDate.getDate() &&
                    utilityDate.getMonth() === selectedDate?.getMonth() &&
                    year === selectedDate.getFullYear()

                  return (
                    <td
                      key={`${index}${day}${month}${year}`}
                      className={classNames(styles.day, {
                        [styles.availableDate]: available,
                        [styles.selectedDate]: selected
                      })}
                      onClick={() =>
                        dateClickHandler(day, utilityDate.getMonth(), year)
                      }
                    >
                      {day}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className={styles.timeZoneContainer}>
        <div className={styles.timeZoneTitle}>
          <FormattedMessage
            id="timePicker.calendarSection.timeZone"
            defaultMessage="Часовой пояс"
          />
        </div>
        <div className={styles.timeZoneContent}>
          (GMT {moment.tz(new Date(), currentTimezone).format('Z')}){' '}
          {new Intl.DateTimeFormat(currentLocale, {
            timeZoneName: 'long'
          })
            .format(new Date())
            .replace(/.*\d,\s/, '')
          }
        </div>
      </div>
    </section>
  )
}
