import { useEffect, useRef } from 'react'
import { FlatButton } from 'elements'

import styles from './Popup.module.scss'

import { ReactComponent as SuccessSVG } from './media/success.svg'
import { ReactComponent as FailedSVG } from './media/failed.svg'

type PopupProps = {
  text: string
  icon: 'success' | 'failed' | string
  buttonText?: string
  buttonAction?: () => void
}

export const Popup = ({ text, icon, buttonText, buttonAction }: PopupProps) => {
  const card = useRef<HTMLDivElement>(null)
  useEffect(() => {
    setTimeout(() => {
      card.current?.classList.add(styles[icon])
    }, 10)
  })

  return (
    <div
      ref={card}
      className={styles.card}
      style={buttonText ? { maxHeight: 240 + 66 } : {}}
    >
      <div className={styles.sent}>
        <SuccessSVG className={styles.successSVG} />
        <FailedSVG className={styles.failedSVG} />
        <p>{text}</p>
        {buttonText && (
          <FlatButton
            color="orange"
            buttonProps={{
              onClick: buttonAction,
              className: styles.button
            }}
          >
            {buttonText}
          </FlatButton>
        )}
      </div>
    </div>
  )
}
