import { useEffect, useRef, useState } from 'react'
import { useAnimationFrame } from 'utils'

import styles from './Cat.module.scss'

import { ReactComponent as E1 } from './media/E/1.svg'
import { ReactComponent as E2 } from './media/E/2.svg'
import { ReactComponent as E3 } from './media/E/3.svg'

import { ReactComponent as W1 } from './media/W/1.svg'
import { ReactComponent as W2 } from './media/W/2.svg'
import { ReactComponent as W3 } from './media/W/3.svg'

import { ReactComponent as N1 } from './media/N/1.svg'
import { ReactComponent as N2 } from './media/N/2.svg'
import { ReactComponent as N3 } from './media/N/3.svg'
import { ReactComponent as N4 } from './media/N/4.svg'

import { ReactComponent as S1 } from './media/S/1.svg'
import { ReactComponent as S2 } from './media/S/2.svg'
import { ReactComponent as S3 } from './media/S/3.svg'
import { ReactComponent as S4 } from './media/S/4.svg'

import { ReactComponent as NW1 } from './media/NW/1.svg'
import { ReactComponent as NW2 } from './media/NW/2.svg'
import { ReactComponent as NW3 } from './media/NW/3.svg'

import { ReactComponent as NE1 } from './media/NE/1.svg'
import { ReactComponent as NE2 } from './media/NE/2.svg'
import { ReactComponent as NE3 } from './media/NE/3.svg'

import { ReactComponent as SE1 } from './media/SE/1.svg'
import { ReactComponent as SE2 } from './media/SE/2.svg'
import { ReactComponent as SE3 } from './media/SE/3.svg'

import { ReactComponent as SW1 } from './media/SW/1.svg'
import { ReactComponent as SW2 } from './media/SW/2.svg'
import { ReactComponent as SW3 } from './media/SW/3.svg'

import { ReactComponent as I1 } from './media/idle/1.svg'
import { ReactComponent as I2 } from './media/idle/2.svg'
import { ReactComponent as I3 } from './media/idle/3.svg'

const catSpeed = 1

const states = {
  idle: [I1, I2, I3],
  '0,-1': [N1, N2, N3, N4],
  '1,-1': [NE1, NE2, NE3],
  '1,0': [E1, E2, E3],
  '1,1': [SE1, SE2, SE3],
  '0,1': [S1, S2, S3, S4],
  '-1,1': [SW1, SW2, SW3],
  '-1,0': [W1, W2, W3],
  '-1,-1': [NW1, NW2, NW3]
}

export const Cat = () => {
  const catBox = useRef<HTMLDivElement>(null)

  const detectCollision = () => {
    let box
    if ((box = catBox.current)) {
      const [mouseX, mouseY] = mouseCoords.current
      const { x, y, height, width } = box.getBoundingClientRect()
      const collides =
        mouseX >= x &&
        mouseX <= x + width &&
        mouseY >= y &&
        mouseY <= y + height

      if (!box.classList.contains(styles.hovered) && collides) {
        box.classList.add(styles.hovered)
      } else if (box.classList.contains(styles.hovered) && !collides) {
        box.classList.remove(styles.hovered)
      }
    }
  }

  const [catData, setCatData] = useState({
    coords: [100, -100],
    state: states.idle[0],
    timePassed: 0
  })
  const mouseCoords = useRef([0, 0])

  const updateMouseCoords = (e: MouseEvent) => {
    mouseCoords.current = [e.clientX, e.clientY]
  }

  const scrollDistance = useRef<number | null>(null)
  const shiftCat = () => {
    const lastScrollDistance = scrollDistance.current
    scrollDistance.current = window.scrollY
    if (lastScrollDistance !== null) {
      const scrollDelta = lastScrollDistance - scrollDistance.current
      if (
        catBox.current &&
        catBox.current.getBoundingClientRect().top + 200 + scrollDelta > 0 &&
        catBox.current.getBoundingClientRect().top - 100 + scrollDelta <
          window.innerHeight
      ) {
        setCatData(prevData => {
          return {
            ...prevData,
            coords: [prevData.coords[0], prevData.coords[1] + scrollDelta]
          }
        })
      }
    }
  }
  useEffect(() => {
    window.addEventListener('mousemove', updateMouseCoords)
    window.addEventListener('scroll', shiftCat)

    return () => {
      window.removeEventListener('scroll', shiftCat)
      window.removeEventListener('mousemove', updateMouseCoords)
    }
  }, [])

  const moveCat = (mouseCoords: number[], deltaTime: number) => {
    const [mouseX, mouseY] = mouseCoords

    setCatData(prevData => {
      const [catX, catY] = prevData.coords
      const [dX, dY] = [Math.sign(mouseX - catX), Math.sign(mouseY - catY)]

      const reachedMouse =
        Math.sqrt((mouseX - catX) ** 2 + (mouseY - catY) ** 2) < 100

      let stateFrames
      if (reachedMouse) {
        stateFrames = states.idle
      } else {
        stateFrames = states[[dX, dY].join(',')]
      }
      const totalFrames = stateFrames.length
      const frameCount = Math.floor(
        ((prevData.timePassed + deltaTime) * 0.005) % totalFrames
      )

      return {
        coords: reachedMouse
          ? prevData.coords
          : [
              Math.round(catX + Math.sign(dX) * catSpeed),
              Math.round(catY + Math.sign(dY) * catSpeed)
            ],
        state: stateFrames[frameCount],
        timePassed:
          frameCount === totalFrames + 1 ? 0 : prevData.timePassed + deltaTime
      }
    })
  }
  useAnimationFrame(deltaTime => {
    moveCat(mouseCoords.current, deltaTime)
    detectCollision()
  })

  const [catX, catY] = catData.coords
  const CatState = catData.state

  return (
    <div
      ref={catBox}
      className={styles.catBox}
      style={{
        top: catY,
        left: catX
      }}
    >
      <CatState />
    </div>
  )
}
