import {
  PropsWithChildren,
  HTMLAttributes,
  useLayoutEffect,
  useRef,
  useEffect,
  useState,
  useCallback
} from 'react'
import { UseMutationState } from 'urql'
import { motion, MotionProps, useAnimation } from 'framer-motion'
import classNames from 'classnames'
import { useMediaQuery } from 'utils'
import { FailedSVG, SuccessSVG } from './Icons'

import styles from './GenericForm.module.scss'

type GenericFormProps = {
  requestState?: UseMutationState
  responseResult?: 'success' | 'failed'
  texts: {
    heading?: string
    success: string
    failed: string
  }
  additionalSentElement?: JSX.Element
  divProps?: HTMLAttributes<HTMLDivElement> & MotionProps
}

export const GenericForm = (props: PropsWithChildren<GenericFormProps>) => {
  const popoutAnimation = useAnimation()

  const formContents = useRef<HTMLDivElement>(null)
  const [cardHeight, setCardHeight] = useState(0)
  const calculateMaxHeight = useCallback(async () => {
    if (formContents.current && !props.responseResult) {
      const { maxHeight } = formContents.current.style
      formContents.current.style.maxHeight = 'none'
      setCardHeight(formContents.current.clientHeight)
      formContents.current.style.maxHeight = maxHeight
    }
  }, [props.responseResult])
  useLayoutEffect(() => {
    ;(async () => {
      await calculateMaxHeight()
    })()
    window.addEventListener('resize', calculateMaxHeight)

    return () => {
      window.removeEventListener('resize', calculateMaxHeight)
    }
  }, [calculateMaxHeight])
  useEffect(() => {
    ;(async () => {
      await popoutAnimation.set('from')
    })()
  }, [popoutAnimation, cardHeight])

  const {
    requestState,
    responseResult,
    texts,
    additionalSentElement,
    divProps
  } = props

  useEffect(() => {
    ;(async () => {
      const showPopout = responseResult || requestState?.error
      if (showPopout) {
        await popoutAnimation.start('to')
      }
    })()
  }, [responseResult, requestState, popoutAnimation])

  const success = responseResult === 'success'
  const failed = responseResult === 'failed' || requestState?.error

  const isSmall = useMediaQuery('screen and (max-width: 479px)')
  const paddingShrunk = isSmall ? '0px 16px 0px' : '0px 44px'
  const paddingExpanded = isSmall ? '32px 16px 24px' : '40px 44px'

  return (
    <div {...divProps} className={classNames(styles.card, divProps?.className)}>
      <motion.div
        ref={formContents}
        className={styles.form}
        animate={popoutAnimation}
        variants={{
          from: {
            opacity: 1,
            maxHeight: cardHeight,
            padding: paddingExpanded
          },
          to: {
            opacity: 0,
            maxHeight: 0,
            padding: paddingShrunk,
            display: 'none',
            transition: {
              duration: 0.5,
              delay: 0.4,
              opacity: {
                duration: 0.3,
                delay: 0
              },
              display: {
                duration: 0,
                delay: 0.8
              }
            }
          }
        }}
        initial="from"
      >
        <h4>{texts.heading}</h4>
        {props.children}
      </motion.div>
      {responseResult && (
        <motion.div
          className={styles.popout}
          animate={popoutAnimation}
          variants={{
            from: {
              visibility: 'hidden',
              maxHeight: 0,
              padding: paddingShrunk
            },
            to: {
              visibility: 'visible',
              maxHeight: 400,
              padding: paddingExpanded,
              transition: {
                duration: 0.5,
                delay: 0.4,
                visibility: {
                  duration: 0,
                  delay: 0.5 + 0.4
                }
              }
            }
          }}
          initial="from"
        >
          {success && <SuccessSVG animate={popoutAnimation} />}
          {failed && <FailedSVG animate={popoutAnimation} />}
          <motion.p
            animate={popoutAnimation}
            variants={{
              from: {
                opacity: 0
              },
              to: {
                opacity: 1,
                transition: {
                  duration: 0.5,
                  delay: 0.9 + 0.5 + 0.6
                }
              }
            }}
          >
            {success && texts.success}
            {failed && texts.failed}
          </motion.p>
          {success && additionalSentElement}
        </motion.div>
      )}
    </div>
  )
}
