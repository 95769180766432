import { StarRating } from 'elements'

import styles from './css/Review.module.scss'

type ReviewProps = {
  name: string
  text: string
  date: string
  avatar: JSX.Element
}

export const Review = (props: ReviewProps) => (
  <article className={styles.card}>
    <header className={styles.personDetails}>
      {props.avatar}
      <div className={styles.nameContainer}>
        <div className={styles.name}>{props.name}</div>
        <StarRating count={5} />
      </div>
    </header>{' '}
    <p>{props.text}</p>
    <span className={styles.date}>{props.date}</span>
  </article>
)
