import { Redirect } from 'react-router-dom'
import {
  ClientArea,
  Consulting,
  ConsultPurchase,
  SoloRoadmap,
  LoginForm,
  PaymentResult,
  PurchaseForm
} from 'components/legacy/services'
import {
  // ClientArea,
  // Consulting,
  // ConsultPurchase,
  // SoloRoadmap
  IntensivePage,
  InstallmentPage,
  MarathonPage,
  Platform,
  OfferPage
} from 'components/current/services'

export {
  LoginForm,
  PaymentResult,
  PurchaseForm,
  ClientArea,
  ConsultPurchase,
  Consulting,
  IntensivePage,
  MarathonPage,
  SoloRoadmap
}

export const serviceRoutes = [
  { component: IntensivePage, path: '/intensive' },
  { component: InstallmentPage, path: '/installment_fix' },
  { component: OfferPage, path: '/offer' },
  { component: MarathonPage, path: '/marathon' },
  { component: Platform, path: '/platform' },
  {
    component: () => <Redirect to='/client_area' />,
    path: '/client_roadmap(.*)'
  },
  { component: SoloRoadmap, path: '/relocation' },
  { component: ClientArea, path: '/client_area' },
  // { component: ConsultPurchase, exact: true, path: '/consult' },
  { component: Consulting, path: '/consulting' }
]
