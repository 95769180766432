import { PropsWithChildren } from 'react'
import { AnimationControls, motion } from 'framer-motion'

import styles from './Icons.module.scss'

const SVGBox = (props: PropsWithChildren<{ animate: AnimationControls }>) => (
  <div className={styles.svgBox}>
    <motion.div
      className={styles.hider}
      animate={props.animate}
      variants={{
        from: {
          width: '60%'
        },
        to: {
          width: 0,
          transition: {
            duration: 0.4,
            delay: 0.9 + 0.5 + 0.6
          }
        }
      }}
      initial="from"
    />
    <motion.div
      className={styles.ripple}
      animate={props.animate}
      variants={{
        from: {
          opacity: 1,
          scale: 0.9
        },
        to: {
          opacity: 0,
          scale: 2,
          transition: {
            duration: 0.5,
            delay: 0.9 + 0.5 + 0.6
          }
        }
      }}
      initial="from"
    />
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      xmlns="http://www.w3.org/2000/svg"
      fill="#ffffff"
    >
      <motion.circle
        cx="27.7"
        cy="27.7002"
        r="26.5"
        strokeWidth="2.4"
        stroke="#7e51ff"
        animate={props.animate}
        variants={{
          from: {
            strokeDashoffset: 170
          },
          to: {
            strokeDashoffset: 0,
            transition: {
              duration: 0.5,
              delay: 0.9 + 0.5,
              ease: 'linear'
            }
          }
        }}
        initial="from"
      />
      {props.children}
    </svg>
  </div>
)

export const SuccessSVG = (props: { animate: AnimationControls }) => (
  <SVGBox {...props}>
    <path
      d="
        M 15 24.5746
        L 23.206 35.123
        L 20.5328 34.6533
        L 44 17
        L 22.8151 37.4641
        L 22.7259 37.5513
        C 22.0799 38.1756 21.0622 38.1446 20.4533 37.4824
        C 20.3202 37.3375 20.216 37.1688 20.1433 36.9959
        L 15 24.5746
        Z"
      fill="#7e51ff"
    />
  </SVGBox>
)

export const FailedSVG = (props: { animate: AnimationControls }) => (
  <SVGBox {...props}>
    <path
      d="M 15.9183 16.7344 L 40.2449 41.0609"
      strokeWidth="2.77551"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="#7e51ff"
    />
    <path
      d="M 40.2449 16.7344 L 15.9183 41.0609"
      strokeWidth="2.77551"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="#7e51ff"
    />
  </SVGBox>
)
