import { useCallback, useEffect, useRef, useState } from 'react'

export type TimerProps = { timeFinish: number; endTimer: () => void }

export const Timer = (props: TimerProps) => {
  const timerInterval = useRef<number>()

  const updateTimer = useCallback(() => {
    setSeconds(props.timeFinish - new Date().getTime() / 1000)
  }, [props.timeFinish])

  const [seconds, setSeconds] = useState(
    props.timeFinish - new Date().getTime() / 1000
  )

  useEffect(() => {
    if (new Date().getTime() / 1000 >= props.timeFinish) {
      props.endTimer()
      clearTimeout(timerInterval.current)
    } else {
      timerInterval.current = window.setTimeout(updateTimer, 1000)
    }
  }, [props, seconds, updateTimer])

  return (
    <>
      {seconds % 60 < 10
        ? `${Math.floor(seconds / 60)}:0${Math.floor(seconds % 60)}`
        : `${Math.floor(seconds / 60)}:${Math.floor(seconds % 60)}`}
    </>
  )
}
