import { useContext, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useFormik } from 'formik'
import PhoneInput from 'react-phone-input-2'
import { useContactUsMutation } from 'generated/graphql'
import { getStoredUserData, gtagEvent, ym } from 'utils'
import { FlatButton } from 'elements'
import { GenericForm, UtmContext } from 'components'

import 'react-phone-input-2/lib/high-res.css'
import styles from './ContactUsForm.module.scss'

type FormValues = {
  name?: string
  email?: string
  phone?: string
  note?: string
}

type FormProps = {
  additionalData?: {
    [key: string]: string | undefined
  }
}

export const ContactUsForm = (props: FormProps) => {
  const intl = useIntl()
  const initialValues = {
    ...getStoredUserData(),
    note: ''
  }

  useEffect(() => {
    gtagEvent({
      event_category: 'feedback',
      event_action: 'form_open',
      event_label: window.location.pathname
    })
    ym('reachGoal', 'feedback_open')
  }, [])

  const getUtmInfo = useContext(UtmContext)
  const [state, executeMutation] = useContactUsMutation()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async values => {
      if (props.additionalData?.specificEvent) {
        gtagEvent({
          event_category: 'feedback',
          event_action: 'form_sent',
          event_label: props.additionalData.specificEvent
        })
        ym('reachGoal', props.additionalData.specificEvent)
      } else {
        gtagEvent({
          event_category: 'feedback',
          event_action: 'form_sent',
          event_label: window.location.pathname
        })
      }
      ym('reachGoal', 'feedback_form')
      const additionalData = props.additionalData
        ? JSON.stringify(props.additionalData)
        : ''
      const requestValues = {
        ...values,
        utmInfo: JSON.stringify(getUtmInfo()),
        additionalData
      }
      const result = await executeMutation(requestValues)
      const status = result.data?.createLead.status
      if (status === 'success') {
        setResult(status)
      } else if (status === 'failed') {
        setResult(status)
      }
    },
    validate: values => {
      const { name, email, phone, note } = values
      const errors: FormValues = {}
      const re =
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/

      if (!re.test(email.toLowerCase()))
        errors.email = intl.formatMessage({
          id: '_common.contactForm.invalidEmail',
          defaultMessage: 'Неверный формат почтового адреса'
        })
      if (!name)
        errors.name = intl.formatMessage({
          id: '_common.contactForm.invalidName',
          defaultMessage: 'Не введено имя'
        })
      if ((phone.length < 11 && phone.startsWith('7')) || phone.length < 9)
        errors.phone = intl.formatMessage({
          id: '_common.contactForm.invalidPhone',
          defaultMessage: 'Неверный формат номера'
        })
      if (note.length > 500) {
        errors.phone = intl.formatMessage({
          id: '_common.contactForm.tooMuchCharacters',
          defaultMessage: 'Кол-во символов не должно превышать 255'
        })
      }
      return errors
    }
  })

  const [result, setResult] = useState<undefined | 'success' | 'failed'>(
    undefined
  )
  const [phone, setPhone] = useState(initialValues.phone)
  useEffect(() => {
    formik.handleChange({ target: { value: phone, name: 'phone' } })
    // eslint-disable-next-line
  }, [phone])

  return (
    <GenericForm
      responseResult={result}
      requestState={state}
      texts={{
        heading: intl.formatMessage({
          id: '_common.contactForm.heading',
          defaultMessage: 'Свяжитесь с нами и мы ответим на ваши вопросы'
        }),
        success: intl.formatMessage({
          id: '_common.contactForm.success',
          defaultMessage:
            'Ваша заявка успешно отправлена, в ближайшее время с вами свяжется наш менеджер'
        }),
        failed: intl.formatMessage({
          id: '_common.contactForm.failed',
          defaultMessage:
            'При отправке возникла техническая ошибка, попробуйте отправить заявку ещё раз'
        })
      }}
    >
      <form
        onSubmit={formik.handleSubmit}
        // onClick={() => {
        //   setResult('success')
        // }}
      >
        <div className={styles.fields}>
          {'name' in formik.touched && formik.errors.name && (
            <div className={styles.error}>{formik.errors.name}</div>
          )}
          <input
            name="name"
            type="text"
            placeholder={intl.formatMessage({
              id: '_common.contactForm.namePlaceholder',
              defaultMessage: 'Ваше имя*'
            })}
            onChange={formik.handleChange}
            value={formik.values.name}
          />
          {'email' in formik.touched && formik.errors.email && (
            <div className={styles.error}>{formik.errors.email}</div>
          )}
          <input
            name="email"
            type="email"
            placeholder={intl.formatMessage({
              id: '_common.contactForm.emailPlaceholder',
              defaultMessage: 'E-mail*'
            })}
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          {'phone' in formik.touched && formik.errors.phone && (
            <div className={styles.error}>{formik.errors.phone}</div>
          )}
          <PhoneInput
            inputProps={{
              placeholder: intl.formatMessage({
                id: '_common.contactForm.phonePlaceholder',
                defaultMessage: 'Ваш номер*'
              })
            }}
            inputClass={styles.phoneInput}
            country="ru"
            value={formik.values.phone}
            onChange={setPhone}
          />
          {'note' in formik.touched && formik.errors.note && (
            <div className={styles.error}>{formik.errors.note}</div>
          )}
          <textarea
            rows={4}
            name="note"
            value={formik.values.note}
            placeholder={intl.formatMessage({
              id: '_common.contactForm.notePlaceholder',
              defaultMessage: 'Примечание'
            })}
            onChange={formik.handleChange}
          />
        </div>
        <div className={styles.bottomRow}>
          <div className={styles.agreement}>
            <FormattedMessage
              id="_common.contactForm.agreement"
              defaultMessage="Я соглашаюсь с обработкой моих персональных данных"
            />
          </div>
          <FlatButton
            color="orange"
            loading={state.fetching}
            disabled={!(formik.dirty && formik.isValid)}
            buttonProps={{
              type: 'submit'
            }}
          >
            <FormattedMessage
              id="_common.contactForm.button"
              defaultMessage="Оставить Заявку"
            />
          </FlatButton>
        </div>
      </form>
    </GenericForm>
  )
}
