import { useGetAllMediaQuery } from 'generated/graphql'
import { Loading } from 'elements'

import styles from './MassMedia.module.scss'

type Media = {
  id: string
  name: string
  link: string
  icon: string
}

export const MassMedia = (props: {title: string}) => {
  const [response] = useGetAllMediaQuery()
  if (!response.data) return <Loading />

  const massMedia: Media[] = response.data.allMedia.filter(m => !!m) as Media[]
  return (
    <div className={styles.container}>
      <div className={styles.title}>{props.title}</div>
      <div className={styles.conveyor}>
        <div className={styles.belt}>
          {[...massMedia, ...massMedia, ...massMedia].map((item, index) => {
            return (
              <a
                key={item.id}
                className={styles.item}
                target="_blank"
                rel="noopener noreferrer"
                href={item.link}
              >
                <img
                  alt={item.name}
                  src={`https://storage.googleapis.com/hello-move-files/${item.icon}`}
                />
              </a>
            )
          })}
        </div>
      </div>
    </div>
  )
}
