import { HTMLProps, useState } from 'react'
import classNames from 'classnames'

import styles from './Dropdown.module.scss'

import { ReactComponent as ArrowDropdownSVG } from './arrow.svg'

type DropdownItem = {
  name: string | null
  displayText: string | null
  onSelect: (item: DropdownItem) => void
}

type DropdownProps = {
  listItems: (DropdownItem | null)[]
  displayText: string
  divProps?: HTMLProps<HTMLDivElement>
  closeOnSelect?: boolean
}

export const Dropdown = (props: DropdownProps) => {
  const { divProps, listItems, displayText } = props
  const [visible, setVisible] = useState(false)
  return (
    <div
      {...divProps}
      className={classNames(styles.dropdownBox, divProps?.className, {
        [styles.visible]: visible
      })}
    >
      {displayText}
      <ArrowDropdownSVG />
      <div className={styles.toggle} onClick={() => setVisible(!visible)} />
      <div className={styles.dropdownList}>
        {listItems.map(
          item =>
            item && (
              <div
                key={item.name}
                className={styles.dropdownItem}
                onClick={() => {
                  item.onSelect(item)
                  if (props.closeOnSelect) setVisible(false)
                }}
              >
                {item.displayText}
              </div>
            )
        )}
      </div>
    </div>
  )
}
