import { useCallback, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Link } from 'elements'

import styles from './css/Logo.module.scss'

import { ReactComponent as HSVG } from './media/H.svg'
import { ReactComponent as LogoTextSVG } from './media/logoText.svg'

// scroll delta history for last 10 scroll events
let scrollDeltaHistory: number[] = new Array(10).fill(0)
let lastScroll = 0

let bounceTimeHistory: number[] = new Array(3).fill(0)
let destroyed = false

export const Logo = () => {
  const logoBox = useRef<HTMLAnchorElement>(null)
  const hIcon = useRef<SVGSVGElement>(null)

  const bounce = () => {
    // "forget" last bounce timestamp
    const lastBounceTimestamp = bounceTimeHistory.shift() as number
    const now = new Date().getTime()
    bounceTimeHistory = [...bounceTimeHistory, now]

    // determine if logo should be destroyed because of extensive bouncing
    if (!destroyed && now - lastBounceTimestamp < 10000) {
      destroyed = true
      logoBox.current?.classList.add(styles.destroyed)
    } else {
      logoBox.current?.classList.add(styles.bounce)
      setTimeout(() => {
        logoBox.current?.classList.remove(styles.bounce)
      }, 300)
    }
    // determine at what angle H should bounce
    const iconBounceType = Math.floor(Math.random() * 6) + 1
    hIcon.current?.classList.add(styles[`bounce${iconBounceType}`])
    setTimeout(() => {
      hIcon.current?.classList.remove(styles[`bounce${iconBounceType}`])
    }, 500)
  }

  const measureScrollSpeed = useCallback(() => {
    // "forget" last delta value
    scrollDeltaHistory.shift()

    const { scrollY } = window
    const delta = lastScroll - scrollY
    // remember this scroll for later use
    lastScroll = scrollY
    // push new delta value only if it's positive (scroll up)
    scrollDeltaHistory = [...scrollDeltaHistory, delta < 0 ? 0 : delta]

    // calculate average speed for last 10 scroll events
    const averageScrollSpeed = scrollDeltaHistory.reduce((a, b) => a + b) / 10
    if (averageScrollSpeed >= 60 && scrollY === 0) {
      bounce()
    }
  }, [])

  const history = useHistory()
  useEffect(() => {
    window.addEventListener('scroll', measureScrollSpeed)
    const unListen = history.listen(() => {
      // reset scroll history on location change
      scrollDeltaHistory = new Array(10).fill(-1000)
      bounceTimeHistory = new Array(5).fill(0)

    })

    return () => {
      window.removeEventListener('scroll', measureScrollSpeed)
      unListen()
    }
  }, [history, measureScrollSpeed])

  return (
    <Link to="/" ref={logoBox} className={styles.logoContainer}>
      <div className={styles.iconBox}>
        <HSVG ref={hIcon} />
      </div>
      <div className={styles.textBox}>
        <LogoTextSVG />
      </div>
    </Link>
  )
}
