import {
  createContext,
  FC,
  useCallback,
  MouseEvent,
  useState,
  useRef
} from 'react'
import { AnimatePresence, motion, useAnimation } from 'framer-motion'

import styles from './Popup.module.scss'

import { ReactComponent as CloseSVG } from './close.svg'

export const PopupContext = createContext<
  (popupElement: JSX.Element | null) => void
>((_: JSX.Element | null) => {})

export const PopupProvider: FC = ({ children }) => {
  const animationControls = useAnimation()

  const content = useRef<HTMLDivElement>(null)
  const closePopup = useCallback(async () => {
    setCurrentPopup(null)
    await animationControls.start('clear')
    // setTimeout(() => {
    //   content.current!.style.filter = ''
    // }, 800)
  }, [animationControls])

  const backdropClick = async (e: MouseEvent) => {
    e.stopPropagation()
    await closePopup()
  }

  const [currentPopup, setCurrentPopup] = useState<JSX.Element | null>(null)
  const showPopup = async (popupElement: JSX.Element | null) => {
    if (popupElement) {
      setCurrentPopup(() => popupElement)
      await animationControls.start('blurred')
    } else {
      await closePopup()
    }
  }

  return (
    <AnimatePresence initial={false}>
      <PopupContext.Provider key="context" value={showPopup}>
        <motion.div
          ref={content}
          className={styles.contentBlur}
          initial="clear"
          animate={animationControls}
          variants={{
            blurred: {
              filter: 'blur(22px)',
              background: 'rgba(36, 32, 40, 0.07)',
              transition: {
                duration: 0,
                delay: 0.2
              }
            },
            clear: {
              filter: 'none',
              background: 'rgba(36, 32, 40, 0.00)',
              transition: {
                duration: 0
              }
            }
          }}
        >
          {children}
        </motion.div>
      </PopupContext.Provider>
      {!!currentPopup && (
        <div
          key="modal"
          className={styles.popupWrapper}
          onClick={backdropClick}
        >
          <motion.div
            className={styles.cardBox}
            onClick={e => e.stopPropagation()}
            variants={{
              hidden: {
                opacity: 0,
                scale: 0.5,
                transition: {
                  duration: 0.3
                }
              },
              visible: {
                opacity: 0.92,
                scale: 1,
                transition: {
                  duration: 0.3
                }
              }
            }}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <CloseSVG onClick={closePopup} />
            {currentPopup}
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  )
}
