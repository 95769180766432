import { createContext, FC, useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { makeQuery } from 'api/makeQuery'
import { GetLangDocument, GetLangQuery, QueryLangArgs } from 'generated/graphql'
import { Loading } from 'elements'
import { localStorageGetItem } from 'utils'

import styles from './css/LanguageProvider.module.scss'

type Languages = {
  abbrs: string[]
  langs: {
    [langAbbr: string]: {
      [textId: string]: string
    }
  }
}

const fetchLang = async (langAbbr: string) => {
  const result = await makeQuery<GetLangQuery, QueryLangArgs>({
    type: 'query',
    gqlQuery: GetLangDocument,
    variables: {
      name: langAbbr
    }
  })
  return result.data
}

const defaultLocale = (): string => {
  if (window.location.pathname.includes('/en/')) return 'en'
  else if ('currentLocale' in window.localStorage)
    return localStorageGetItem('currentLocale')
  else if (navigator.language.substr(0, 2) === ('ru' || 'en')) {
    window.localStorage.setItem(
      'currentLocale',
      navigator.language.substr(0, 2)
    )
    return navigator.language.substr(0, 2)
  } else {
    return 'ru'
  }
}

export const LanguageContext = createContext<
  [string, (langAbbr: string) => void]
>(['ru', (_: string) => {}])

export const LanguageProvider: FC = ({ children }) => {
  const [currentLocale, setCurrentLocale] = useState(defaultLocale)

  const [loadedLangs, setLoadedLangs] = useState<Languages>({
    abbrs: [],
    langs: {}
  })

  const [loading, setLoading] = useState(true)
  // load texts from server when user changes locale
  // but only if language hasn't already been loaded
  useEffect(() => {
    ;(async () => {
      // if (currentLocale === 'en' && !window.location.pathname.includes('/en')) {
      //   const pathname =
      //     window.location.pathname === '/'
      //       ? '/en'
      //       : `/en${window.location.pathname}`
      //   window.location.replace(pathname)
      // } else if (
      //   currentLocale === 'ru' &&
      //   window.location.pathname.includes('/en')
      // ) {
      //   const newUrl = window.location.pathname.replace('/en', '')
      //   window.location.replace(newUrl)
      // }
      if (!loadedLangs.abbrs.includes(currentLocale)) {
        setLoading(true)
        const data = await fetchLang(currentLocale)
        if (data?.lang) {
          const { name, textsJson } = data.lang
          setLoadedLangs(prevLangs => {
            const newLangs = { ...prevLangs }
            newLangs.abbrs.push(name)
            newLangs.abbrs.sort()
            newLangs.langs[name] = JSON.parse(textsJson)
            return newLangs
          })
          setLoading(false)
          window.localStorage.setItem('currentLocale', name)
        }
      }
    })()
  }, [currentLocale, loadedLangs.abbrs])

  const selectLang = (langAbbr: string) => {
    setCurrentLocale(langAbbr)
    window.localStorage.setItem('currentLocale', langAbbr)
  }

  const { langs } = loadedLangs

  // on locale change, display texts of the loaded language instead of defaultMessages
  const currentLang =
    currentLocale in langs ? langs[currentLocale] : Object.values(langs)[0]

  return (
    <>
      {loading && (
        <div className={styles.loadingScreen}>
          <Loading />
        </div>
      )}
      {!!loadedLangs.abbrs.length && (
        <IntlProvider
          locale={
            loadedLangs.abbrs.includes(currentLocale)
              ? currentLocale
              : defaultLocale()
          }
          defaultLocale="ru"
          messages={currentLang}
        >
          <LanguageContext.Provider value={[currentLocale, selectLang]}>
            {children}
          </LanguageContext.Provider>
        </IntlProvider>
      )}
    </>
  )
}
