import { isDev } from 'utils'

type imgType = ({
  fallback,
  alt
}: {
  fallback: string
  alt: string
}) => JSX.Element

export const ImgNextGen: imgType = ({ fallback, alt }) => {
  return isDev ? (
    <img src={fallback} alt={alt} />
  ) : (
    <picture>
      <source
        srcSet={`${fallback.slice(0, fallback.lastIndexOf('png'))}webp`}
        type="image/webp"
      />
      <source
        srcSet={`${fallback.slice(0, fallback.lastIndexOf('png'))}jp2`}
        type="image/jp2"
      />
      <img src={fallback} alt={alt} />
    </picture>
  )
}
