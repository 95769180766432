import styles from './MediaBlock.module.scss'

import { ReactComponent as SnobSVG } from './media/snob.svg'
import { ReactComponent as RbkSVG } from './media/rbk.svg'
import { ReactComponent as RepublicSVG } from './media/republic.svg'
import { ReactComponent as RusbaseSVG } from './media/rusbase.svg'
import { ReactComponent as VcSVG } from './media/vc.svg'
import { ReactComponent as MeduzaSVG } from './media/meduza.svg'

const logos = [
  ['https://snob.ru/entry/205319/', SnobSVG],
  ['https://pro.rbc.ru/demo/607599e59a794704ef733204', RbkSVG],
  ['https://republic.ru/posts/100065', RepublicSVG],
  ['https://rb.ru/opinion/netherlands-for-startups/', RusbaseSVG],
  [
    'https://vc.ru/migrate/120319-keys-kak-poluchit-vnzh-britanii-onlayn-bez-vlozheniy-vo-vremya-karantina',
    VcSVG
  ],
  [
    'https://meduza.io/paragraph/2021/06/23/po-statistike-kazhdyy-shestoy-rossiyanin-zadumyvaetsya-ob-emigratsii-kakie-tut-est-varianty-maksimalno-korotkiy-spisok',
    MeduzaSVG
  ]
]

export const MediaBlock = (props: { title: string }) => (
  <section className={styles.container}>
    <h2>{props.title}</h2>
    <div className={styles.media}>
      {logos.map(([link, Logo], index) => (
        <a
          href={link as string}
          target="_blank"
          rel="noreferrer noopener"
          key={index}
          className={styles.source}
        >
          <Logo />
        </a>
      ))}
    </div>
  </section>
)
