import { Redirect } from 'react-router-dom'
import {
  AllProgramsPage,
  //MainPage,
  ProgramPage,
  // Relopedia,
  // ReviewsPage,
  // TelegramRedirect,
  // WebinarBonus,
  // WebinarPage
} from 'components/legacy/core'
import {
  // AllProgramsPage,
  MainPage,
  // ProgramPage,
  Relopedia,
  ReviewsPage,
  TelegramRedirect,
  Endscreen,
  WebinarPage
} from 'components/current/core'

export const WebinarTildaRedirect = () => {
  const anchor = document.createElement('a')
  anchor.href = 'https://webinar.hello-move.com'
  anchor.target = '_blank'
  anchor.click()
  return <>redirecting to webinar...</>
}

export const coreRoutes = [
  { component: MainPage, exact: true, path: '/' },
  // { component: WebinarTildaRedirect, exact: true, path: '/webinar' },
  { component: WebinarPage, exact: true, path: '/webinar' },
  { component: TelegramRedirect, path: '/webinar/telegram' },
  { component: ReviewsPage, exact: true, path: '/reviews' },
  { component: Relopedia, path: '/relopedia' },
  { component: Endscreen, exact: true, path: '/endscreen/:clickmeetingId' },
  {
    component: () => <Redirect to="/programs" />,
    exact: true,
    path: ['/all_programs', '/p/:slug']
  },
  { component: AllProgramsPage, exact: true, path: '/programs' },
  { component: ProgramPage, exact: true, path: '/programs/:slug' }
]
