import { useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useFormik } from 'formik'
import PhoneInput from 'react-phone-input-2'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import { Button } from 'elements'
import { getStoredUserData, gtagEvent, stripeKey, ym } from 'utils'
import { useRequestPaymentMutation } from 'generated/graphql'
import { GenericForm } from 'components'

import styles from './css/PurchaseForm.module.scss'

type FormValues = {
  email?: string
  name?: string
  phone?: string
}

type PurchaseFormProps = {
  title: string
  bottomText: string
  purchaseType: string
  attachment?: boolean
}

export const PurchaseForm = (props: PurchaseFormProps) => {
  const intl = useIntl()
  const initialValues = getStoredUserData()

  const stripe = useRef<Stripe | null>(null)
  useEffect(() => {
    ;(async () => {
      stripe.current = await loadStripe(stripeKey)
      gtagEvent({
        event_category:
          props.purchaseType === 'fix_price' ? 'prepay' : 'consultation',
        event_action: 'form_open'
      })
      ym(
        'reachGoal',
        props.purchaseType === 'fix_price' ? 'prepay_open' : 'consultation_open'
      )
    })()
  }, [props.purchaseType])
  const [state, executeMutation] = useRequestPaymentMutation()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async values => {
      gtagEvent({
        event_category:
          props.purchaseType === 'fix_price' ? 'prepay' : 'consultation',
        event_action: 'form_sent'
      })
      ym(
        'reachGoal',
        props.purchaseType === 'fix_price' ? 'prepay_form' : 'consultation_form'
      )
      const result = await executeMutation({
        ...values,
        purchaseType: props.purchaseType,
        additionalInfo: { attachment: props.attachment }
      })
      if (!result.data) {
        setResult('failed')
      } else {
        const { sessionId } = result.data.createPurchase
        setTimeout(() => {
          stripe.current?.redirectToCheckout({
            sessionId: sessionId as string
          })
        }, 3000)
        setResult('success')
      }
    },
    validate: values => {
      const { email, name, phone } = values
      const errors: FormValues = {}
      const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/

      if (!re.test(email.toLowerCase()))
        errors.email = intl.formatMessage({
          id: 'consultPurchasePage.purchaseForm.invalidEmail',
          defaultMessage: 'Неверный формат почтового адреса'
        })

      if (!name) {
        errors.name = intl.formatMessage({
          id: 'consultPurchasePage.purchaseForm.invalidName',
          defaultMessage: 'Имя не может быть пустым'
        })
      }

      if (phone.length < 8) {
        errors.phone = intl.formatMessage({
          id: 'consultPurchasePage.purchaseForm.invalidName',
          defaultMessage: 'Слишком короткий телефон'
        })
      }
      return errors
    }
  })

  const [result, setResult] = useState<undefined | 'success' | 'failed'>(
    undefined
  )

  const [phone, setPhone] = useState(initialValues.phone)
  useEffect(() => {
    formik.handleChange({ target: { value: phone, name: 'phone' } })
    // eslint-disable-next-line
  }, [phone])

  return (
    <GenericForm
      responseResult={result}
      requestState={state}
      texts={{
        heading: props.title,
        success: intl.formatMessage({
          id: 'consultPurchasePage.purchaseForm.success',
          defaultMessage:
            'Сейчас вы будете перенаправлены на страницу платёжной системы'
        }),
        failed: intl.formatMessage({
          id: 'consultPurchasePage.purchaseForm.failed',
          defaultMessage:
            'При отправке возникла техническая ошибка, попробуйте ещё раз'
        })
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.fields}>
          {'name' in formik.touched && formik.errors.name && (
            <div className={styles.error}>{formik.errors.name}</div>
          )}
          <input
            name="name"
            type="text"
            placeholder={intl.formatMessage({
              id: 'consultPurchasePage.purchaseForm.namePlaceholder',
              defaultMessage: 'Ваши имя и фамилия*'
            })}
            onChange={formik.handleChange}
            value={formik.values.name}
          />
          {'email' in formik.touched && formik.errors.email && (
            <div className={styles.error}>{formik.errors.email}</div>
          )}
          <input
            name="email"
            type="email"
            placeholder={intl.formatMessage({
              id: 'consultPurchasePage.purchaseForm.emailPlaceholder',
              defaultMessage: 'E-mail*'
            })}
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          {'phone' in formik.touched && formik.errors.phone && (
            <div className={styles.error}>{formik.errors.phone}</div>
          )}
          <PhoneInput
            inputProps={{
              placeholder: intl.formatMessage({
                id:
                  '_common.contactForm.consultPurchasePage.purchaseForm.phonePlaceholder',
                defaultMessage: 'Ваш номер*'
              })
            }}
            inputClass={styles.phoneInput}
            country="ru"
            value={formik.values.phone}
            onChange={setPhone}
          />
        </div>
        <div className={styles.bottomRow}>
          <div className={styles.agreement}>{props.bottomText}</div>
          <Button
            color="basic"
            loading={state.fetching}
            disabled={!(formik.dirty && formik.isValid)}
            buttonProps={{
              type: 'submit'
            }}
          >
            <FormattedMessage
              id="consultPurchasePage.purchaseForm.button"
              defaultMessage="Оплатить"
            />
          </Button>
        </div>
      </form>
    </GenericForm>
  )
}
