import { HTMLAttributes, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { StarRating, Link } from 'elements'
import { LanguageContext, OtherProgram } from 'components'

import styles from './CardProgram.module.scss'

import countryPNG from './media/fallback.png'

interface CardProgramProps extends OtherProgram {
  additionalProps?: HTMLAttributes<HTMLAnchorElement>
}

export const CardProgram = (props: CardProgramProps) => {
  const [currentLocale] = useContext(LanguageContext)
  return (
    <Link
      to={`/programs/${props.slug}`}
      {...props.additionalProps}
      className={classNames(styles.card, props.additionalProps?.className)}
    >
      <div className={styles.imgContainer}>
        <img
          className={styles.imgCountry}
          src={props.cardPicture ? props.cardPicture : countryPNG}
          alt="country"
        />
      </div>
      <div className={styles.textData}>
        <div className={styles.countryInfo}>
          <div className={styles.allTextCardContainer}>
            <h4 className={styles.countryName}>
              {props.country.names[currentLocale]}
            </h4>
            <h5 className={styles.cardMain}>{props.programType}</h5>
            <div className={styles.text}>{props.shortDescription}</div>
          </div>
          <div className={styles.qualities}>
            <div className={styles.term}>
              <div className={styles.qualityTitle}>
                <FormattedMessage
                  id="_common.cardProgram.termTitle"
                  defaultMessage="Сроки"
                />
              </div>
              <div className={styles.qualityText}>{props.terms}</div>
            </div>
            <div className={styles.difficulty}>
              <div className={styles.qualityTitle}>
                <FormattedMessage
                  id="_common.cardProgram.difficultyTitle"
                  defaultMessage="Сложность"
                />
              </div>
              <div className={styles.stars}>
                <StarRating count={props.difficulty} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}
