import { FormattedDate, FormattedTime, FormattedMessage } from 'react-intl'
import classNames from 'classnames'

import styles from './AvailableTime.module.scss'

type AvailableTimeProps = {
  time: Date
  onTimeConfirm: (selectedTime: Date) => void
  selectTime: (selectedTime: Date) => void
  selectedTime: Date | null
}

export const AvailableTime = (props: AvailableTimeProps) => {
  const { time, onTimeConfirm } = props

  const timePlusMonth = new Date(
    time.getFullYear(),
    time.getMonth() + 1,
    time.getDate(),
    time.getHours(),
    time.getMinutes() - new Date().getTimezoneOffset()
  )

  const selected = timePlusMonth.getTime() === props.selectedTime?.getTime()

  return (
    <div
      className={styles.availableTimeContainer}
      onClick={() => props.selectTime(timePlusMonth)}
    >
      <div
        className={classNames(styles.availableTime, {
          [styles.selectedTime]: selected
        })}
      >
        <span>
          <FormattedDate value={timePlusMonth} month="short" day="numeric" />{' '}
          <FormattedTime
            value={timePlusMonth}
            hour="numeric"
            minute="numeric"
          />
        </span>
        <span>
          <FormattedMessage
            id="timePicker.chooseTimeSection.timeIsFree"
            defaultMessage="свободно"
          />
        </span>
      </div>
      <div
        className={classNames(styles.buttonChooseTime, {
          [styles.visible]: selected
        })}
        onClick={() => {
          onTimeConfirm(props.selectedTime as Date)
          window.scrollTo(0, 0)
        }}
      >
        <span>
          <FormattedMessage
            id="timePicker.chooseTimeSection.button"
            defaultMessage="Выбрать"
          />
        </span>
      </div>
    </div>
  )
}
