import { Route, RouteProps, useLocation } from 'react-router-dom'
import { PropsWithChildren, useEffect } from 'react'

// this component provides automatic scroll-to-top functionality on render
export const ScrollTopRoute = (props: PropsWithChildren<RouteProps>) => {

  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])
  const { children, path, ...rest } = props
  return (
      <Route path={path} {...rest}>
        {children}
      </Route>
  )
}
