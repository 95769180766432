import { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { isDev} from 'utils'
import { ScrollTopRoute } from 'elements'
import { LanguageContext } from 'components'
import {
  coreRoutes,
  serviceRoutes,
  utilityRoutes,
  otherRoutes,
  UtilityWrapper
} from 'components/connector'

import '@csstools/normalize.css'
import './normalize.css'
import './App.scss'

function App() {
  const history = useHistory()
  useEffect(() => {
    if (!isDev) {
      gtag('config', 'UA-154566426-2', {
        custom_map: {
          dimension1: 'client_id',
          dimension2: 'user_id',
          page_path: window.location.pathname
        }
      })
    } else {
      // hide django tags while in development
      document.getElementById('root')?.style.setProperty('margin-top', '-18px')
    }
  }, [history])

  const [currentLocale] = useContext(LanguageContext)
  const localizedPathPrefix = currentLocale === 'en' ? '/en' : ''
  return (
    <HelmetProvider>
      <Helmet>
        {window.location.host !== 'hello-move.com' && (
          <meta name="robots" content="noindex, nofollow" />
        )}
      </Helmet>
      <UtilityWrapper>
        {[
          ...coreRoutes,
          ...serviceRoutes,
          ...utilityRoutes,
          ...otherRoutes
        ].map((routeProps, index) => {
          const { path } = routeProps
          let localizedPath: string | string[]
          if (typeof path === 'string') {
            localizedPath = `${localizedPathPrefix}${path}`
          } else {
            localizedPath = path.map(
              pathName => `${localizedPathPrefix}${pathName}`
            )
          }
          return (
            <ScrollTopRoute {...routeProps} key={index} path={localizedPath} />
          )
        })}
      </UtilityWrapper>
    </HelmetProvider>
  )
}

export default App
