import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Wrapper } from 'elements'
import { Review } from './Review'

import styles from './css/Reviews.module.scss'

import { ReactComponent as ArrowSVG } from './media/arrow.svg'
import { ReactComponent as NoAvatarSVG } from './media/no-avatar.svg'
import TatianaPNG from './media/tatiana.png'
import ArinaPNG from './media/arina.png'
import ElenaPNG from './media/elena.png'

SwiperCore.use([Navigation])

const reviewsData = [
  {
    name: 'Ольга Ильина',
    text:
      'Я Благодарю всю всю команду Hello Move! Ребята большие профессионалы, они стали родными с первой же консультации. Знают ответ на любой вопрос, а если где то не знают, то узнают в течении ближайшего времени! Такое профессиональное ведение клиентов мне кажется вообще мало у кого и у этой компании многим можно научится, особенно заботы и полное погружение в каждого клиента. Я желаю всей команде большого успешного будущего, что бы вы всегда были на высоте и вам улыбалась удача и прибавлялось количество клиентов. Ребят, вы космос! Сияйте, развивайтесь! Мы с вами ♥️ а скоро станем к вам ещё ближе🔥',
    date: '2 декабря 2020 г.',
    avatar: <NoAvatarSVG />
  },
  {
    name: 'Arina Zatonskaya',
    text:
      'Выражаю огромную благодарность компании Hello Move! У нас все получилось! Мы справились! Ребята очень внимательно изучили наши возможности и направления. Очень грамотно и понятно нам подготовили наш проект, оформили его в такую красивую упаковку, что мы не ожидали сами. Полное ведение от начала до конца, инструкции на столько хорошо и понятно даны, что даже человек не понимающий ничего, поймёт))) Команда Hello Move сильная и надёжная, глубокие знания по всем вопросам и моментам, мне кажется что даже люди живущие в этих странах не знают всего, что знает компания Hello Move. Всё нюансы предусмотрены, проект уникальный и что самое интересное, то что есть хороший выбор пакетов на любой карман и желания. Я желаю ребятам развиваться, расти командой! Вы стали родными для нас, такая забота и внимание к нам♥️ благодарим и двигаемся с вами дальше🙏♥️',
    date: '19 ноября 2020 г.',
    avatar: <img src={ArinaPNG} alt="user avatar" />
  },
  {
    name: 'Tatiana Puznevskay',
    text:
      'Хочу поблагодарить команду профессионалов своего дела за оказанную мне поддержку! У меня есть замечательная идея, но совершенно нет опыта в оформлении и подачи проекта. Мне повезло встретить именно эту компанию на пути к своей цели. В дружелюбной и доброжелательной обстановке провели полный и подробный анализ всего, проинформировали меня о таких правилах и деталях,которые я бы точно сама не узнала. Я получила чёткий и подробный план следующий действий, а также информационную и моральную поддержку, что очень значимо в другой стране',
    date: '18 ноября 2020 г.',
    avatar: <img src={TatianaPNG} alt="user avatar" />
  },
  {
    name: 'Лилия Сабирова',
    text:
      'Очень благодарна компании HelloMove! Благодаря им сегодня получила ВНЖ Латвии. Скажу честно, это был долгий путь. Сотрудничать начали в мае. За это время подались на несколько стран. Было 4 отказа. Я очень сильно переживала, что ничего не получится и мы связались не с той компанией. Но ребята проявили себя как отличные специалисты миграционного рынка, психологи и партнеры. Несмотря на то, что не все шло гладко, они не бросили мой кейс, не слились, а стойко шли со мной "рука об руку" к намеченной цели! И мы до нее дошли! Еще раз спасибо всем за участие над моим кейсом! Отдельное спасибо менеджеру проекта Саре!',
    date: '29 ноября 2020 г.',
    avatar: <NoAvatarSVG />
  },
  {
    name: 'Елена Панова',
    text: `
    Благодаря Hello Move, я смогла на год уехать учиться в Испанию.
    Как мы все знаем лучше всего учить язык в его языкой среде. Я долго искала программы для изучения испанского, но не могла найти подходящую для себя по цене и условиям. Стоит заметить что программа должна быть обязательно аккредитована Instituto Cervantes, иначе вы не сможете получить визу. Спустя долгое время неудачных поисков я решила обратиться к Hello Move по совету своей знакомой.
    Ребята подобрали мне аккредитованную школу, бюджетную программу (800 евро за курс), помогли собрать документы за короткий срок, а так же нашли проверенных юристов в Испании. Всего лишь 1 месяц и вуаля я уже с визой и изучаю язык в Аликанте.
    Спасибо большое ребятам за помощь!
    `,
    date: '10 октября 2020 г.',
    avatar: <img src={ElenaPNG} alt="user avatar" />
  },
  {
    name: 'Mary Ivanova',
    text: `
    Я хотела бы поблагодарить команду Hello Move, которая помогла мне поступить в Willem de Kooning Academy в Роттердаме.
    На самом деле мой уровень IELTS до поступления в университет был 3.5, и я не могла найти никого, кто мог бы помочь мне с поступлением в университет и оформлением визы. Hello Move помог мне найти образовательный центр, который успешно подготовил меня к экзамену IELTS, мой балл 6.0; руководил мной, пока я не поступила в университет; поддержал меня в процессе подачи заявления на визу. 
    Спасибо вам огромное, ребята!
    `,
    date: '2 октября 2020 г.',
    avatar: <NoAvatarSVG />
  },
  {
    name: 'Ulyana Azarova',
    text:
      'Я искала программу обучения для бакалавра по направлению дизайн, но к сожалению многие сайты не дают свежую информацию о ценах на обучения и о содержании программы. Менеджеры с Хелло Мув помогли мне найти университет в Амстердаме, связали с адмишнс напрямую где я смогла узнать какие курсы они предлагают, условия поступления, и необходимые документы для зачисления.  Где-то за 1.5-2 месяца мне смогли оформить визу и найти место для проживания. На самом деле очень приятно видеть когда о клиенте заботятся как о родном человеке. Из плюсов хочу выделить  что ребята серьезно упрощают вам ваш процесс переезда. С ними вам не нужно думать какие программы обучения сейчас актуальны, какие документы необходимы на визу, куда нужно сдать тонну бумажек с подписями, где снять жилье и многие другие вещи. Обращайтесь к ребятам за сервисом, не пожалеете потраченных денег)',
    date: '16 сентября 2020 г.',
    avatar: <NoAvatarSVG />
  }
]

let swiperInstance: SwiperCore | undefined

type ReviewsProps = {
  className?: string
}

export const Reviews = (props: ReviewsProps) => {
  const [reviewsCount, setReviewsCount] = useState(1)
  return (
    <div className={classNames(styles.background, props.className)}>
      <Wrapper>
        <h2 className="default-heading">
          <FormattedMessage
            id="mainPage.reviews.heading"
            defaultMessage="Отзывы наших клиентов"
          />
        </h2>
      </Wrapper>
      <Wrapper className={styles.wrapper}>
        <div className={styles.container}>
          <ArrowSVG
            className={styles.left}
            onClick={() => {
              swiperInstance?.slidePrev()
            }}
          />
          <ArrowSVG
            className={styles.right}
            onClick={() => {
              swiperInstance?.slideNext()
            }}
          />
          <section className={styles.slider}>
            <Swiper
              onSwiper={swiper => {
                swiperInstance = swiper
              }}
              direction="horizontal"
              speed={600}
              spaceBetween={24}
              grabCursor
              loop={true}
              slidesPerView={2}
              breakpoints={{
                1800: {
                  slidesPerView: 3
                }
              }}
            >
              {reviewsData.map((item, index) => (
                <SwiperSlide key={index}>
                  <Review {...item} />
                </SwiperSlide>
              ))}
            </Swiper>
          </section>
          <section className={styles.sliderMobile}>
            {reviewsData.map(
              (item, index) =>
                index < reviewsCount && <Review key={index} {...item} />
            )}
            {reviewsCount !== reviewsData.length && (
              <span
                className={styles.seeMore}
                onClick={() => setReviewsCount(reviewsCount + 1)}
              >
                <FormattedMessage
                  id="mainPage.reviews.seeMore"
                  defaultMessage="Посмотреть ещё"
                />
                <ArrowSVG />
              </span>
            )}
          </section>
        </div>
      </Wrapper>
    </div>
  )
}
